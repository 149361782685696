<div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">

        <div class="row DashboardPage">
            <div class="col-md-6 col-lg-3">
                <div class="card info">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="avatar avatar-icon avatar-lg avatar-blue">
                                <i class="anticon anticon-dollar"></i>
                            </div>
                            <div class="m-l-10 ">
                                <h2 class="m-b-0">RM23,523</h2>
                                <p class="m-b-0 text-muted w-100">Total Project value</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="card info">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="avatar avatar-icon avatar-lg avatar-cyan">
                                <i class="anticon anticon-line-chart"></i>
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">RM23,523</h2>
                                <p class="m-b-0 text-muted">Quotation Value</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="card info">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="avatar avatar-icon avatar-lg avatar-gold">
                                <i class="anticon anticon-profile"></i>
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">3,685</h2>
                                <p class="m-b-0 text-muted">Total Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="card info">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="avatar avatar-icon avatar-lg avatar-purple">
                                <i class="anticon anticon-user"></i>
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">1,832</h2>
                                <p class="m-b-0 text-muted">No. of Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-left: 0px; margin-right: 0px;">
            <div class="col-lg-12 m-t-0 m-b-20 d-flex" style="padding: 0px;">
                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-gold d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px; "
                        href="#">
                        <i class="anticon anticon-snippets"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Snippets</p>
                </div>

                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-green d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px;"
                        href="#">
                        <i class="anticon anticon-solution"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Solutions</p>
                </div>
                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-blue d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px;"
                        href="#">
                        <i class="anticon anticon-tags"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Tags</p>
                </div>
                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-magenta d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px;"
                        href="#">
                        <i class="anticon anticon-project"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Project</p>
                </div>
                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-red d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px;"
                        href="#">
                        <i class="anticon anticon-mobile"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Mobile</p>
                </div>
                <div class="col-lg-2"
                    style="display: flex; justify-content: center; flex-direction:column; align-items: center; ">
                    <a class="quick-links avatar avatar-lg avatar-icon avatar-purple d-flex"
                        style="flex-direction:column; justify-content: center; align-items: center; width:90px; height:90px;"
                        href="#">
                        <i class="anticon anticon-team"></i>
                    </a>
                    <p class="m-t-5 m-b-5">Team</p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <div class="card">
                    <div class="card-body">

                        <div class="page-header no-gutters">
                            <div class="row align-items-md-center">
                                <div class="col-md-6">
                                    <div class="row">
                                        <h2 class="m-b-0">Active Projects</h2>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-md-right m-v-10">
                                        <div class="btn-group m-r-10">
                                            <button id="list-view-btn" type="button" class="btn btn-default btn-icon"
                                                data-toggle="tooltip" data-placement="bottom" title="List View">
                                                <i class="anticon anticon-ordered-list"></i>
                                            </button>
                                            <button id="card-view-btn" type="button"
                                                class="btn btn-default btn-icon active" data-toggle="tooltip"
                                                data-placement="bottom" title="Card View">
                                                <i class="anticon anticon-appstore"></i>
                                            </button>
                                        </div>
                                        <button class="btn btn-primary" data-toggle="modal"
                                            data-target="#create-new-project">
                                            <i class="anticon anticon-plus"></i>
                                            <span class="m-l-5">View All</span>
                                        </button>
                                        <router-link to="/new-project">
                                            <button class="btn btn-primary" style="margin-left: 5px;">
                                                <i class="anticon anticon-plus"></i>
                                                <span class="m-l-5">New Project</span>
                                            </button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div id="card-view">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between">
                                                    <div class="media">
                                                        <div>
                                                            <h5 class="m-b-0">Skypod chan</h5>
                                                            <span class="text-muted font-size-13">34 Tasks</span>
                                                        </div>
                                                    </div>
                                                    <div class="dropdown dropdown-animated scale-left">
                                                        <a class="text-gray font-size-18" href="javascript:void(0);"
                                                            data-toggle="dropdown">
                                                            <i class="anticon anticon-ellipsis"></i>
                                                        </a>
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-eye"></i>
                                                                <span class="m-l-10">View</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-edit"></i>
                                                                <span class="m-l-10">Edit</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-delete"></i>
                                                                <span class="m-l-10">Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="m-t-25">Phase: Discovery, Reason: Deadline over</p>
                                                <div class="m-t-30">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="font-weight-semibold">Progress</span>
                                                        <span class="font-weight-semibold">73%</span>
                                                    </div>
                                                    <div class="progress progress-sm m-t-10">
                                                        <div class="progress-bar" role="progressbar" style="width: 73%">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="m-t-20">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <span class="badge badge-pill badge-green">Quote</span>
                                                        </div>

                                                        <a class="m-r-5 mt-2" href="javascript:void(0);"
                                                            data-toggle="tooltip" title="End dates">
                                                            <div class="">
                                                                <p class="text-danger font-size-13">17 May</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between">
                                                    <div class="media">
                                                        <div>
                                                            <h5 class="m-b-0">Aseana Yap </h5>
                                                            <span class="text-muted font-size-13">78 Tasks</span>
                                                        </div>
                                                    </div>
                                                    <div class="dropdown dropdown-animated scale-left">
                                                        <a class="text-gray font-size-18" href="javascript:void(0);"
                                                            data-toggle="dropdown">
                                                            <i class="anticon anticon-ellipsis"></i>
                                                        </a>
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-eye"></i>
                                                                <span class="m-l-10">View</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-edit"></i>
                                                                <span class="m-l-10">Edit</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-delete"></i>
                                                                <span class="m-l-10">Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="m-t-25">Phase: Wet Works, Reason: Deadline over</p>
                                                <div class="m-t-30">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="font-weight-semibold">Progress</span>
                                                        <span class="font-weight-semibold">73%</span>
                                                    </div>
                                                    <div class="progress progress-sm m-t-10">
                                                        <div class="progress-bar" role="progressbar" style="width: 73%">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="m-t-20">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <span class="badge badge-pill badge-gold">Confirm</span>
                                                        </div>
                                                        <div>
                                                            <a class="mr-5 mt-10" href="javascript:void(0);"
                                                                data-toggle="tooltip" title="Erin Gonzales">
                                                                <div class="">
                                                                    <p class="text-danger font-size-13">20 May</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between">
                                                    <div class="media">
                                                        <div>
                                                            <h5 class="m-b-0">Aliali June </h5>
                                                            <span class="text-muted font-size-13">54 Tasks</span>
                                                        </div>
                                                    </div>
                                                    <div class="dropdown dropdown-animated scale-left">
                                                        <a class="text-gray font-size-18" href="javascript:void(0);"
                                                            data-toggle="dropdown">
                                                            <i class="anticon anticon-ellipsis"></i>
                                                        </a>
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-eye"></i>
                                                                <span class="m-l-10">View</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-edit"></i>
                                                                <span class="m-l-10">Edit</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-delete"></i>
                                                                <span class="m-l-10">Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="m-t-25">Phase: Discovery, Reason: Deadline over</p>
                                                <div class="m-t-30">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="font-weight-semibold">Progress</span>
                                                        <span class="font-weight-semibold">73%</span>
                                                    </div>
                                                    <div class="progress progress-sm m-t-10">
                                                        <div class="progress-bar" role="progressbar" style="width: 73%">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="m-t-20">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <span class="badge badge-pill badge-gold">Quote</span>
                                                        </div>

                                                        <a class="m-r-5 mt-2" href="javascript:void(0);"
                                                            data-toggle="tooltip" title="End date">
                                                            <div class="">
                                                                <p class="text-danger font-size-13">10 June</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between">
                                                    <div class="media">
                                                        <div>
                                                            <h5 class="m-b-0">Aliali June </h5>
                                                            <span class="text-muted font-size-13">54 Tasks</span>
                                                        </div>
                                                    </div>
                                                    <div class="dropdown dropdown-animated scale-left">
                                                        <a class="text-gray font-size-18" href="javascript:void(0);"
                                                            data-toggle="dropdown">
                                                            <i class="anticon anticon-ellipsis"></i>
                                                        </a>
                                                        <div class="dropdown-menu">
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-eye"></i>
                                                                <span class="m-l-10">View</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-edit"></i>
                                                                <span class="m-l-10">Edit</span>
                                                            </button>
                                                            <button class="dropdown-item" type="button">
                                                                <i class="anticon anticon-delete"></i>
                                                                <span class="m-l-10">Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="m-t-25">Phase: Discovery, Reason: Deadline over</p>
                                                <div class="m-t-30">
                                                    <div class="d-flex justify-content-between">
                                                        <span class="font-weight-semibold">Progress</span>
                                                        <span class="font-weight-semibold">73%</span>
                                                    </div>
                                                    <div class="progress progress-sm m-t-10">
                                                        <div class="progress-bar" role="progressbar" style="width: 73%">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="m-t-20">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <span class="badge badge-pill badge-blue">Quote</span>
                                                        </div>

                                                        <a class="m-r-5 mt-2" href="javascript:void(0);"
                                                            data-toggle="tooltip" title="End date">
                                                            <div class="">
                                                                <p class="text-dark font-size-13">10 June</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card d-none" id="list-view">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Project</th>
                                                    <th>Tasks</th>
                                                    <th>Members</th>
                                                    <th>Progress</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div>
                                                                <h5 class="m-b-0">Mind Cog App</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>31 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Pamela Wanda">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-7.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Darryl Day">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-2.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar bg-success" role="progressbar"
                                                                    style="width: 100%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                <i class="anticon anticon-check-o text-success"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-2.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Mill Real Estate</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>56 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Virgil Gonzales">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-4.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Erin Gonzales">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="2 More">
                                                                    <div class="avatar avatar-text avatar-sm">
                                                                        <span class="text-dark">+2</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar bg-success" role="progressbar"
                                                                    style="width: 100%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                <i class="anticon anticon-check-o text-success"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-3.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Eastern Sack</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>21 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Lilian Stone">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-10.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Victor Terry">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-11.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="3 More">
                                                                    <div class="avatar avatar-text avatar-sm">
                                                                        <span class="text-dark">+3</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar" role="progressbar"
                                                                    style="width: 87%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                87%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-4.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Good Beat</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>38 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Marshall Nichols">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-3.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Pamela Wanda">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-7.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar" role="progressbar"
                                                                    style="width: 73%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                73%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-5.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Fortier Studio</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>68 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Erin Gonzales">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Lilian Stone">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-10.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar" role="progressbar"
                                                                    style="width: 73%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                73%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-6.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Indi Wheel Web</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>68 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Virgil Gonzales">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-4.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Darryl Day">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-2.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="2 More">
                                                                    <div class="avatar avatar-text avatar-sm">
                                                                        <span class="text-dark">+2</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar" role="progressbar"
                                                                    style="width: 62%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                62%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-7.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Austin Lab</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>90 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Lilian Stone">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-8.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Victor Terry">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-9.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar" role="progressbar"
                                                                    style="width: 62%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                62%
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image rounded">
                                                                <img src="assets/images/others/thumb-8.jpg" alt="">
                                                            </div>
                                                            <div class="m-l-10">
                                                                <h5 class="m-b-0">Moody Agency</h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>165 Tasks</span>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Lilian Stone">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-8.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Darryl Day">
                                                                    <div class="avatar avatar-image avatar-sm">
                                                                        <img src="assets/images/avatars/thumb-2.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="3 More">
                                                                    <div class="avatar avatar-text avatar-sm">
                                                                        <span class="text-dark">+3</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="progress progress-sm w-100 m-b-0">
                                                                <div class="progress-bar bg-danger" role="progressbar"
                                                                    style="width: 28%"></div>
                                                            </div>
                                                            <div class="m-l-10">
                                                                <i class="anticon anticon-close-o text-danger"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="dropdown dropdown-animated scale-left">
                                                            <a class="text-gray font-size-18" href="javascript:void(0);"
                                                                data-toggle="dropdown">
                                                                <i class="anticon anticon-ellipsis"></i>
                                                            </a>
                                                            <div class="dropdown-menu">
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-eye"></i>
                                                                    <span class="m-l-10">View</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-edit"></i>
                                                                    <span class="m-l-10">Edit</span>
                                                                </button>
                                                                <button class="dropdown-item" type="button">
                                                                    <i class="anticon anticon-delete"></i>
                                                                    <span class="m-l-10">Delete</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">

                    <img src="assets/images/others/img-2.jpg" style="height:220px; object-fit:contain" />

                </div>
            </div>
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">Upcoming Alerts</h5>
                            <div>
                                <a href="" class="btn btn-default btn-sm">View All</a>
                            </div>
                        </div>
                        <div class="m-t-30">
                            <div class="d-flex m-b-20">
                                <div class="text-center">
                                    <div class="avatar avatar-text avatar-blue avatar-lg rounded">
                                        <span class="font-size-22">17</span>
                                    </div>
                                </div>
                                <div class="m-l-20">
                                    <h5 class="m-b-0">
                                        <a class="text-dark">UI Discussion</a>
                                    </h5>
                                    <p class="m-b-0">Execute core that as result.</p>
                                </div>
                            </div>
                            <div class="d-flex m-b-20">
                                <div class="text-center">
                                    <div class="avatar avatar-text avatar-blue avatar-lg rounded">
                                        <span class="font-size-22">17</span>
                                    </div>
                                </div>
                                <div class="m-l-20">
                                    <h5 class="m-b-0">
                                        <a class="text-dark">UI Discussion</a>
                                    </h5>
                                    <p class="m-b-0">Execute core that as result.</p>
                                </div>
                            </div>
                            <div class="d-flex m-b-20">
                                <div class="text-center">
                                    <div class="avatar avatar-text avatar-cyan avatar-lg rounded">
                                        <span class="font-size-22">21</span>
                                    </div>
                                </div>
                                <div class="m-l-20">
                                    <h5 class="m-b-0">
                                        <a class="text-dark">Project Schdule</a>
                                    </h5>
                                    <p class="m-b-0">Special cloth alert always.</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="text-center">
                                    <div class="avatar avatar-text avatar-gold avatar-lg rounded">
                                        <span class="font-size-22">25</span>
                                    </div>
                                </div>
                                <div class="m-l-20">
                                    <h5 class="m-b-0">
                                        <a class="text-dark">Design Discussion</a>
                                    </h5>
                                    <p class="m-b-0">Let us wax poetic about.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <v-date-picker color="indigo" style="width:100%; border: 0 solid;" v-model="date" />
                    </div>
                </div>

            </div>
        </div>

        <div class="row ">
            <div class="col-md-12 col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5>Top Panels</h5>
                            <div>
                                <a href="javascript:void(0);" class="btn btn-sm btn-default">View All</a>
                            </div>
                        </div>
                        <div class="m-t-30">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Panel</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>ABC Designs</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Designer</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>ABC Designs</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Designer</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>ABC Designs</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Designer</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>ABC Designs</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Designer</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>ABC Designs</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Designer</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-9">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5>Top 20 Products</h5>
                            <div class='d-flex w-30 justify-content-between'>
                                <a href="javascript:void(0);" class="btn btn-sm btn-primary btn-tone">View Store</a>
                                <a href="javascript:void(0);" class="btn btn-sm btn-default">View All</a>
                            </div>
                        </div>
                        <div class="m-t-30">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Gallery ID</th>
                                            <th>Ordered</th>
                                            <th style="max-width: 70px">Rendered</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-9.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>Island Kitchen</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>81</td>
                                            <td>RM1,912.00</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="progress progress-sm w-100 m-b-0">
                                                        <div class="progress-bar bg-success" style="width: 82%"></div>
                                                    </div>
                                                    <div class="m-l-10">
                                                        82
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-10.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>Island Kitchen</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>26</td>
                                            <td>RM1,377.00</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="progress progress-sm w-100 m-b-0">
                                                        <div class="progress-bar bg-success" style="width: 61%"></div>
                                                    </div>
                                                    <div class="m-l-10">
                                                        61
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image">
                                                        <img src="assets/images/others/thumb-11.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>Wooden Rhino</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>71</td>
                                            <td>RM9,212.00</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="progress progress-sm w-100 m-b-0">
                                                        <div class="progress-bar bg-danger" style="width: 23%"></div>
                                                    </div>
                                                    <div class="m-l-10">
                                                        23
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-12.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>Red Chair</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>79</td>
                                            <td>RM1,298.00</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="progress progress-sm w-100 m-b-0">
                                                        <div class="progress-bar bg-warning" style="width: 54%"></div>
                                                    </div>
                                                    <div class="m-l-10">
                                                        54
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="media align-items-center">
                                                    <div class="avatar avatar-image ">
                                                        <img src="assets/images/others/thumb-13.jpg" alt="">
                                                    </div>
                                                    <div class="m-l-10">
                                                        <span>Wristband</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>60</td>
                                            <td>RM7,376.00</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="progress progress-sm w-100 m-b-0">
                                                        <div class="progress-bar bg-success" style="width: 76%"></div>
                                                    </div>
                                                    <div class="m-l-10">
                                                        76
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       
    </div>

   

</div>