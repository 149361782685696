export default {
  BASEURL: "http://13.212.245.233:8080/v1/idman",
  // user mng
  REGISTER: "/panel/register",
  LOGIN: "/panel/login",
  // PROJECT MANAGEMENT
  ALLPROJECTS: "/project/all",
  SINGLEPROJECT: "/project/get",
  DUPLICATE: "/project/duplicate",
  NEWPROJECT: "/project/add",
  PROJECTBREAKDOWN: "/project/breakdown/add",
  GETSIGNEDURL: "/media/uploadurl/get",

  // contact management
  ADDCONTACT: "/panel/contact/add",
  DELETECONTACT: "/panel/contact/delete",
  GETCONTACT: "/panel/contact/get",
  UPDATECONTACT: "/panel/contact/update",
  GETALLCONTACTS: "/panel/contact/all",

  // receipt
  GENERATERECEIPT: "/project/receipt/generate",
  GENERATEQUOTEBALANCE : "/project/bq/generate",
  ALLRECEIPT: "/project/receipt/all"
};
