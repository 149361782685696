<div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">


        <!-- Modal -->
        <el-dialog title="" v-model="centerDialogVisible" width="70%" height="500px"
            style="height: 100%; max-height: 500px!important;" center>

            <h5 class="modal-title" id="exampleModalCenterTitle">Enter new task</h5>
            <div style="display: flex;">
                <input type="text" class="form-control" placeholder="Enter the task description" v-model="taskdetails">
                <el-button type="primary" class="m-l-10" @click="confirmAddTask()">Add Task</el-button>
            </div>
            <div class="m-t-10 m-b-10">
                <h5 class="modal-title" id="exampleModalCenterTitle">Add new contact</h5>
                <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                        <span>Choose a contact</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                            <img src="assets/images/avatars/thumb-3.jpg" alt="" />
                            <span>Chiamaka Eze</span>
                        </a>
                        <a class="dropdown-item" href="#">
                            <img src="assets/images/avatars/thumb-3.jpg" alt="" />
                            <span>Oluchi Eze</span>
                        </a>
                        <a class="dropdown-item" href="#">
                            <img src="assets/images/avatars/thumb-3.jpg" alt="" />
                            <span>Egaverere Eze</span>
                        </a>
                    </div>
                </div>

            </div>
            <div class="m-t-10 m-b-10">
                <h5 class="modal-title" id="exampleModalCenterTitle">Add new status</h5>
                <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                        <span>Update phase status</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">Not started yet</a>
                        <a class="dropdown-item" href="#">Started</a>
                        <a class="dropdown-item" href="#">Completed</a>
                    </div>
                </div>

            </div>
            <el-col title="phase.title" v-for="phase in phaseUpdate">
                <li>{{phase.title}}</li>


            </el-col>

            <!-- <template #footer>
  <span class="dialog-footer">

  </span>
</template> -->
        </el-dialog>

        <el-dialog title="" v-model="showProductDialog" width="60%" center>

            <h5 class="modal-title" id="showProductDialogCenterTitle">Enter new price</h5>
            <input type="number" class="form-control" placeholder="Enter the new price" v-model="newprice">
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showProductDialog = false">Cancel</el-button>
                    <el-button type="primary" @click="setprice(-1)">Make Complimentary</el-button>
                    <el-button type="primary" @click="setprice(0)">Save Price</el-button>
                </span>
            </template>
        </el-dialog>


        <el-dialog title="" v-model="addProductDialog" width="60%" center>

            <h5 class="modal-title" id="showProductDialogCenterTitle">Enter Product details</h5>
            <form>

                <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Upload product image <em>click to upload</em></div>
                    <template #tip>
                        <div class="el-upload__tip">
                            jpg/png files with a size less than 200kb
                        </div>
                    </template>
                </el-upload>
                <br>

                <div class="row">
                    <div class="col">
                        <label for="inputState">Enter Product name</label>
                        <input type="text" class="form-control" placeholder="Product name">
                    </div>
                    <div class="col">
                        <label for="inputState">Enter product description</label>
                        <input type="text" class="form-control" placeholder="Product description">
                    </div>
                </div>


                <el-divider></el-divider>
                <h4> Product pricing</h4>
                <div class="row">
                    <div class="col">
                        <label for="inputState">Number of units</label>
                        <input type="number" class="form-control" placeholder="Units">
                    </div>
                    <div class="col">
                        <label for="inputState">Price per unit</label>
                        <input type="number" class="form-control" placeholder="Unit price">
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label for="inputState">Mark up per unit</label>
                        <input type="number" class="form-control" placeholder="Units">
                    </div>
                    <div class="col">
                        <br>
                        <label>Source for this product</label>
                        <div class="switch m-r-10">
                            <input type="checkbox" id="switch-1" checked="">
                            <label for="switch-1"></label>
                        </div>

                    </div>
                </div>
            </form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="	addProductDialog = false">Cancel</el-button>
                    <el-button type="primary" @click="setprice(0)">Add Product</el-button>
                </span>
            </template>
        </el-dialog>


        <el-dialog title="" v-model="dialogVisible" width="30%">
            <span>Are you sure you want to delete this</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click.prevent="dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="confirmDeleteTask()">Confirm</el-button>
                </span>
            </template>
        </el-dialog>


        <div class="container-fluid">

            <div class="row ">

                <div class="col text-left m-b-10 m-t-5">

                    <el-button @click="$router.go(-1)" type="primary" icon="el-icon-arrow-left">Previous Page
                    </el-button>

                </div>
            </div>


            <div class="row">

                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="media align-items-center">
                                    <div class="avatar avatar-image rounded">
                                        <img src="assets/images/others/thumb-3.jpg" alt="">
                                    </div>
                                    <div class="m-l-10">
                                        <h4 class="font-size-18 m-b-0">Iridiscent Bank</h4>
                                    </div>
                                </div>
                                <div style="display: flex; align-items: center; justify-content: center;">
                                    <span class="badge badge-pill badge-blue" style="font-size:16px">Quotation</span>
                                </div>
                            </div>
                            <div class="m-t-40">
                                <h6>Client Details:</h6>
                                <div class="card">
                                    <div class="card-body" style="padding:0.3rem 1.5rem">
                                        <div class="row">

                                            <div class="col">
                                                <ul class="list-unstyled m-t-10">
                                                    <li class="row">
                                                        <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                            <i class="m-r-10 text-primary anticon anticon-user"></i>
                                                            <span>Name: Bob Black</span>
                                                        </p>
                                                    </li>
                                                    <li class="row">
                                                        <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                            <i class="m-r-10 text-primary anticon anticon-mail"></i>
                                                            <span>Email: test@gmail.com</span>
                                                        </p>
                                                    </li>
                                                    <li class="row">
                                                        <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                            <i class="m-r-10 text-primary anticon anticon-phone"></i>
                                                            <span>Phone: 08101234567</span>
                                                        </p>
                                                    </li>

                                                </ul>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-md-flex m-t-30 align-items-center justify-content-between">
                            <div class="d-flex align-items-center m-t-10">
                                <span class="text-dark font-weight-semibold m-r-10 m-b-5">Team: </span>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Erin Gonzales">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                    </div>
                                </a>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Darryl Day">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-2.jpg" alt="">
                                    </div>
                                </a>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Marshall Nichols">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-3.jpg" alt="">
                                    </div>
                                </a>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Virgil Gonzales">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-4.jpg" alt="">
                                    </div>
                                </a>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Riley Newman">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-6.jpg" alt="">
                                    </div>
                                </a>
                                <a class="m-r-5 m-b-5" href="javascript:void(0);" data-toggle="tooltip" title="Pamela Wanda">
                                    <div class="avatar avatar-image" style="width: 30px; height: 30px;">
                                        <img src="assets/images/avatars/thumb-7.jpg" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="m-t-10">
                                <span class="font-weight-semibold m-r-10 m-b-5 text-dark">Due Date: </span>
                                <span>10 Feb 2019</span>
                            </div>
                        </div> -->
                        </div>
                        <div>
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#project-details-tasks">Phases
                                        (10)</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#project-details-comments">Activity</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#project-details-attachment">Uploads</a>
                                </li>
                            </ul>
                            <div class="tab-content m-t-15 p-25">
                                <div class="tab-pane fade show active" id="project-details-tasks">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4>Phases</h4>
                                            <p>Please configure the specific tasks in each phase</p>

                                            <el-col class="col-md-12 m-t-10" v-model="activeName"
                                                style="display: flex; flex-wrap:wrap; justify-content: space-evenly;">
                                                <el-card class="col-md-2 m-h-5 m-b-15 col-lg-6 phases-card"
                                                    style="max-width: 300px; width: 100%; height: 15 0px;"
                                                    title="phase.title" name="phase.id" v-for="(phase, index) in phases"
                                                    :key="index">
                                                    <template #title>
                                                        <p>{{phase.title}}</p>
                                                        <div class="row" style="width:100%; height: 100%;">
                                                            <div class="col-lg-6 text-left" style=" height: 100%;">
                                                                {{index + 1 }}. {{phase.title}}
                                                            </div>

                                                        </div>
                                                    </template>
                                                    <div class="if" v-if="!phase.hasproduct">
                                                        <div style="display: flex; align-items: center; justify-content: flex-end;"
                                                            class="text-right m-t-10 m-b-5">
                                                            <span class="badge badge-pill badge-blue"
                                                                style="font-size:12px">Not Started yet</span>
                                                        </div>
                                                        <div class="m-l-5">
                                                            <p style="font-weight: bold;" class="m-b-0">{{phase.title}}
                                                            </p>
                                                            <div class="m-t-5 m-b-5"
                                                                style="display: flex; justify-content: space-between; align-items: center;">
                                                                <p style="font-size: 12px; margin: 0;">
                                                                    {{phase.tasks.length}} Tasks</p>
                                                                <el-button
                                                                    style="min-height: 30px; max-height: 30px; font-size: 12px; padding: 0px 20px;"
                                                                    type="primary" @click="addTask(phase)">Add New Task
                                                                </el-button>
                                                            </div>

                                                        </div>
                                                        <div class="d-flex  m-t-10 m-l-5"
                                                            style="display: flex; flex-direction: column;">
                                                            <span
                                                                class="text-dark font-weight-semibold font-size-12 m-r-10 m-b-5">Panel
                                                                Members: </span>
                                                            <div
                                                                style="display: flex; flex-wrap: wrap; justify-content: flex-start;">
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Erin Gonzales">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Erin Gonzales">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="else" v-else>
                                                        <div style="display: flex; align-items: center; justify-content: flex-end;"
                                                            class="text-right m-t-10 m-b-5">
                                                            <span class="badge badge-pill badge-blue"
                                                                style="font-size:12px">Not Started yet</span>
                                                        </div>
                                                        <div class="m-l-5">
                                                            <p style="font-weight: bold;" class="m-b-0">{{phase.title}}
                                                            </p>
                                                            <div class="m-t-5 m-b-5"
                                                                style="display: flex; justify-content: space-between; align-items: center;">
                                                                <p style="font-size: 12px; margin: 0;">
                                                                    {{phase.tasks.length}} Tasks</p>
                                                                <el-button
                                                                    style="min-height: 30px; max-height: 30px; font-size: 12px; padding: 0px 20px;"
                                                                    type="primary" @click="addTask(phase)">Add New Task
                                                                </el-button>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex  m-t-10 m-l-5"
                                                            style="display: flex; flex-direction: column;">
                                                            <span
                                                                class="text-dark font-size-12 font-weight-semibold m-r-10 m-b-5">Panel
                                                                Members: </span>
                                                            <div
                                                                style="display: flex; flex-wrap: wrap; justify-content: flex-start;">
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Erin Gonzales">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Darryl Day">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-2.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Marshall Nichols">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-3.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Virgil Gonzales">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-4.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Riley Newman">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-6.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Pamela Wanda">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-7.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Darryl Day">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-2.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Marshall Nichols">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-3.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Virgil Gonzales">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-4.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Riley Newman">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-6.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                                <a class="m-r-5 m-b-5" href="javascript:void(0);"
                                                                    data-toggle="tooltip" title="Pamela Wanda">
                                                                    <div class="avatar avatar-image"
                                                                        style="width: 30px; height: 30px;">
                                                                        <img src="assets/images/avatars/thumb-7.jpg"
                                                                            alt="">
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <el-table v-if="!phase.hasproduct" :data="phase.tasks" :show-header="false" style="width: 100%">
                                                        <el-table-column label="Date" width="50">
                                                            <template #default="scope">
                                                                <h5  >{{index + 1 }}.{{scope.$index}}</h5>
                                                            </template>
                                                        </el-table-column>


<el-table-column
  prop="title"
  label="Name"
  >
</el-table-column>

<el-table-column
  fixed="right"
  label="Operations"
  width="120">
  <template #default="scope">
    <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
  </template>
</el-table-column>
</el-table>

<el-table v-else
:data="phase.products"
style="width: 100%">
<el-table-column

label=""
width="50">
<template #default="scope">
  <h5  >{{index + 1 }}.{{scope.$index}}</h5>
</template>
</el-table-column>

<el-table-column
label="Image"
width="150">

<template #default="scope">


<div class="demo-image__placeholder">
<div class="block"> 
  <el-image :src="scope.row.productimage"></el-image>
</div>

</div>
</template>
</el-table-column>
<el-table-column
prop="productname"
label="Product Name"
>
</el-table-column>

<el-table-column
prop="desc"
label="Product Desc"
>
</el-table-column>
<el-table-column
prop="sku"
label="SKU"
>
</el-table-column>
<el-table-column
prop="quantity"
label="Quantity"
>
</el-table-column>

<el-table-column
prop="unitprice"
label="Unit Price"
>
</el-table-column>

<el-table-column
prop="markup"
label="Mark per unit cost"
>
</el-table-column>

<el-table-column
prop="total"
label="Total"
>
</el-table-column>

<el-table-column
fixed="right"
label="Operations"
width="120">
<template #default="scope">
  <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
</template>
</el-table-column>
</el-table> -->

                                                </el-card>
                                            </el-col>

                                            <!-- <el-collapse  v-model="activeName">
                                    <el-collapse-item title="phase.title" name="phase.id" v-for="(phase, index) in phases" >
                                      <template #title>
                                        <div class="row" style="width:100%; height: 100%;" >
                                          <div class="col-lg-6 text-left" style=" height: 100%;">
                                            {{index + 1 }}. {{phase.title}}
                                          </div>
                                          <div class="col-lg-6 text-right" style="height: 100%;">
                        
                                            <div class="btn-group">
                                            
                        
                                              <button  class="btn btn-default">
                        
                                                <span v-if="phase.hasPrice">Cost: {{ $filters.currencyUSD(phase.price) }}</span>
                                                <span v-else>Complimentary</span>
                        
                                                  
                                              </button>
                                              <button class="btn btn-icon btn-primary btn-rounded" @click="openprice(index)">
                                                <i class="anticon anticon-edit"></i>
                                            </button>
                                             
                                          </div>
                                          </div>
                                        </div>
                                      </template>
                                      <el-table v-if="!phase.hasproduct"
                                          :data="phase.tasks"
                                          :show-header="false"
                                          style="width: 100%">
                                          <el-table-column
                                
                                            label="Date"
                                            width="50">
                                            <template #default="scope">
                                              <h5  >{{index + 1 }}.{{scope.$index}}</h5>
                                            </template>
                                          </el-table-column>
                        
                                         
                                          <el-table-column
                                            prop="title"
                                            label="Name"
                                            >
                                          </el-table-column>
                                        
                                          <el-table-column
                                            fixed="right"
                                            label="Operations"
                                            width="120">
                                            <template #default="scope">
                                              <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
                                            </template>
                                          </el-table-column>
                                        </el-table>
                                       
                                        <el-table v-else
                                        :data="phase.products"
                                        style="width: 100%">
                                        <el-table-column
                              
                                          label=""
                                          width="50">
                                          <template #default="scope">
                                            <h5  >{{index + 1 }}.{{scope.$index}}</h5>
                                          </template>
                                        </el-table-column>
                        
                                        <el-table-column
                                        label="Image"
                                        width="150">
                        
                                        <template #default="scope">
                        
                                         
                                          <div class="demo-image__placeholder">
                                          <div class="block"> 
                                            <el-image :src="scope.row.productimage"></el-image>
                                          </div>
                                          
                                        </div>
                                        </template>
                                      </el-table-column>
                                        <el-table-column
                                          prop="productname"
                                          label="Product Name"
                                          >
                                        </el-table-column>
                        
                                        <el-table-column
                                        prop="desc"
                                        label="Product Desc"
                                        >
                                      </el-table-column>
                                      <el-table-column
                                      prop="sku"
                                      label="SKU"
                                      >
                                    </el-table-column>
                                    <el-table-column
                                    prop="quantity"
                                    label="Quantity"
                                    >
                                  </el-table-column>
                        
                                  <el-table-column
                                  prop="unitprice"
                                  label="Unit Price"
                                  >
                                </el-table-column>
                        
                                <el-table-column
                                prop="markup"
                                label="Mark per unit cost"
                                >
                              </el-table-column>
                        
                              <el-table-column
                              prop="total"
                              label="Total"
                              >
                            </el-table-column>
                                      
                                        <el-table-column
                                          fixed="right"
                                          label="Operations"
                                          width="120">
                                          <template #default="scope">
                                            <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
                                          </template>
                                        </el-table-column>
                                      </el-table>
                        
                                        <div class="row" style="width:100%; margin-top:15px" >
                                          <div class="col-lg-6 text-left">
                                          </div>
                                          <div class="col-lg-6 text-right">
                        
                                            
                        
                                            <el-button  v-if="!phase.hasproduct" @click.prevent="addTask(index)" type="primary" icon="el-icon-plus">Add task</el-button>
                                            <el-button  v-else @click.prevent="addProduct(index)" type="primary" icon="el-icon-plus">Add Product</el-button>
                                          </div>
                                        </div>
                                    </el-collapse-item>
                                  
                                  </el-collapse> -->

                                            <div class="row ">

                                                <div class="col text-right m-t-20">

                                                    <el-button type="primary">Confirm Project</el-button>

                                                </div>
                                            </div>
                                            <!-- <hr> -->




                                        </div>
                                    </div>


                                </div>
                                <div class="tab-pane fade" id="project-details-comments">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Activities</h4>
                                            </div>
                                            <div class="card-body">
                                                <ul class="timeline timeline-sm">
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-cyan">
                                                                <i class="anticon anticon-check"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-4.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Virgil Gonzales</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">10:44 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Complete
                                                                            task </span>
                                                                        <span class="m-l-5"> Prototype Design</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-blue">
                                                                <i class="anticon anticon-link"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-8.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Lilian Stone</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">8:34 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Attached
                                                                            file </span>
                                                                        <span class="m-l-5"> Mockup Zip</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-purple">
                                                                <i class="anticon anticon-message"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-1.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Erin Gonzales</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">8:34 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Commented
                                                                        </span>
                                                                        <span class="m-l-5"> 'This is not our
                                                                            work!'</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-purple">
                                                                <i class="anticon anticon-message"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-6.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Riley Newman</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">8:34 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Commented
                                                                        </span>
                                                                        <span class="m-l-5"> 'Hi, please done this
                                                                            before tommorow'</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-red">
                                                                <i class="anticon anticon-delete"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-7.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Pamela Wanda</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">8:34 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Removed
                                                                        </span>
                                                                        <span class="m-l-5"> a file</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="timeline-item">
                                                        <div class="timeline-item-head">
                                                            <div class="avatar avatar-icon avatar-sm avatar-gold">
                                                                <i class="anticon anticon-file-add"></i>
                                                            </div>
                                                        </div>
                                                        <div class="timeline-item-content">
                                                            <div class="m-l-10">
                                                                <div class="media align-items-center">
                                                                    <div class="avatar avatar-image">
                                                                        <img src="assets/images/avatars/thumb-3.jpg"
                                                                            alt="">
                                                                    </div>
                                                                    <div class="m-l-10">
                                                                        <h6 class="m-b-0">Marshall Nichols</h6>
                                                                        <span class="text-muted font-size-13">
                                                                            <i class="anticon anticon-clock-circle"></i>
                                                                            <span class="m-l-5">5:21 PM</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="m-t-20">
                                                                    <p class="m-l-20">
                                                                        <span
                                                                            class="text-dark font-weight-semibold">Create
                                                                        </span>
                                                                        <span class="m-l-5"> this project</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="project-details-attachment">
                                    <div class="card">
                                        <div class="card-body" style="padding-bottom: 0px;">
                                            <div class="col-md-7" style="padding: 0; margin-bottom: 20px;">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="customFile">
                                                    <label class="custom-file-label" for="customFile">Choose file to
                                                        upload</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="padding: 0; margin-bottom: 10px;">
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-cyan rounded m-r-15">
                                                            <i
                                                                class="anticon anticon-file-exclamation font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Mockup.zip</h6>
                                                            <span class="font-size-13 text-muted">7MB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-cyan rounded m-r-15">
                                                            <i
                                                                class="anticon anticon-file-exclamation font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Mockup.zip</h6>
                                                            <span class="font-size-13 text-muted">7MB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-cyan rounded m-r-15">
                                                            <i
                                                                class="anticon anticon-file-exclamation font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Mockup.zip</h6>
                                                            <span class="font-size-13 text-muted">7MB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-cyan rounded m-r-15">
                                                            <i
                                                                class="anticon anticon-file-exclamation font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Mockup.zip</h6>
                                                            <span class="font-size-13 text-muted">7MB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-blue rounded m-r-15">
                                                            <i class="anticon anticon-file-word font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Guideline.doc</h6>
                                                            <span class="font-size-13 text-muted">128 KB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file" style="min-width: 200px;">
                                                    <div class="media align-items-center">
                                                        <div class="avatar avatar-icon avatar-gold rounded m-r-15">
                                                            <i class="anticon anticon-file-image font-size-20"></i>
                                                        </div>
                                                        <div>
                                                            <h6 class="mb-0">Logo.png</h6>
                                                            <span class="font-size-13 text-muted">128 KB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Content Wrapper END -->
    </div>
</div>