<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->

    <div class="main-content">
      <div class="page-header">
        <div class="card">
          <div class="card-body">
            <el-steps
              :active="0"
              process-status="wait"
              finish-status="success"
              simple
            >
              <el-step title="Create" icon="el-icon-edit"></el-step>
              <el-step title="Project Details" icon="el-icon-s-claim"></el-step>
              <el-step title="Schedule" icon="el-icon-date"></el-step>
              <el-step title="Quotation" icon="el-icon-tickets"></el-step>
            </el-steps>
          </div>
        </div>

        <!-- ending -->
      </div>
      <div class="card">
        <div class="card-body">
          <h4>Existing Project</h4>
          <p>Duplicate an existing project</p>

          <form
            name="existing-project-form"
            @submit.prevent="submitExistingProject"
          >
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <select
                    v-model="projectId"
                    placeholder="pick existing ptoject"
                    class="form-control"
                    style="min-width: 180px"
                    @change="changeProjectID"
                  >
                    <option selected value="">Select project</option>
                    <option
                      :value="index + 1"
                      v-for="(project, index) in projects"
                      :key="project.id"
                    >
                      {{ project.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-primary">Search</button>
              </div>
            </div>
          </form>

          <h4>New Project</h4>
          <p>Enter the details below to create a new Project</p>

          <div class="m-t-25" style="max-width: 1000px">
            <form @submit.prevent="submit" name="new-project-form">
              <div class="row">
                <div class="col">
                  <label>Enter Project Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Project name"
                    v-model="newProjectName"
                  />
                </div>
                <div class="col">
                  <label for="inputState">Select building type</label>
                  <select
                    v-model="newProjectBuildingType"
                    id="inputState"
                    class="form-control"
                  >
                    <option value="" selected>Choose...</option>
                    <option value="CONDO">Condo</option>
                    <option value="LANDED">Landed</option>
                    <option value="RETAIL">Retail</option>
                    <option value="COMMERCIAL">Commercial</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label>Address</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Location"
                  v-model="newProjectAddress"
                />
              </div>

              <div class="row">
                <div class="col">
                  <label>Select project type </label>
                  <select v-model="newProjectType" class="form-control">
                    <option value="" selected>Choose...</option>
                    <option value="PACKAGES">Packages</option>
                    <option value="DESIGN ONLY">Design only</option>
                    <option value="DESIGN AND BUILD">Design & Build</option>
                    <option value="BUILDING ONLY">Build only</option>
                    <option value="FURNITURES ONLY">Furnitures only</option>
                    <option value="OTHERS">Others</option>
                  </select>
                </div>

                <div class="col" v-if="!isDisabled">
                  <label for="clientname">Enter project description</label>
                    <!-- :disabled= isDisabled -->
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter the project description"
                    v-model="newProjectDesc"
                  />
                </div>
              </div>

              <hr />
              <h5>Client details</h5>
              <p>Existing client?</p>
              <form
            name="existing-client-form"
            @submit.prevent="searchExistingClients"
          >
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Existing client id"
                    v-model.trim="existingClientId"
                  />
                </div>
              </div>
              <div class="col">
                <button class="btn btn-primary">Search</button>
              </div>
            </div>
          </form>

            <p>Enter your client details</p>
              <div class="row">
                <div class="col">
                  <label for="clientname">Enter client's name</label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter client's name"
                    v-model="newProjectClientName"
                  />
                </div>
                <div class="col">
                  <label>Enter client's phone number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone number"
                    v-model="newProjectClientPhone"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Enter client's email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="newProjectClientMail"
                />
              </div>

              <h5>Upload AiHouse file</h5>
              <p>Upload the excel document exported from AiHOuse</p>

              <input type="file" @change="onChange" />

              <div></div>
              <br />
              <button class="btn btn-primary">Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
</template>

<script>
import XLSX from "xlsx";
import { mapActions } from "vuex";

import { ElLoading } from "element-plus";
import apiservice from "../../api/apiservice";
import urls from "../../api/apiUrl";
import showError from "../../composables/showError";

export default {
  data() {
    return {
      file: null,
      existingProjectId: "",
      isError: false,
      errorMessage: "",
      newProjectName: "",
      newProjectBuildingType: "",
      newProjectAddress: "",
      newProjectType: "",
      newProjectStatus: "QUOTATION",
      newProjectClientName: "",
      newProjectClientPhone: "",
      newProjectClientMail: "",
      newProjectDesc: "None",
      passedData: [],
      existingClientId: "",
      projects: [],
      projectId: "",
    };
  },

  computed: {
    projectID() {
      if (!isNaN(this.projectId) && !this.projectId == "") {
        return this.projects[this.projectId - 1].id;
      }
    },
    newProjectNameFiltered() {
      if (this.newProjectName) {
        return this.newProjectName;
      } else {
        this.showError("Please enter a value in the project name field");
      }
    },

    isDisabled() {
      console.log(this.newProjectType);
      if(this.newProjectType == "OTHERS"){
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },

    ...mapActions(["toggleError", "setErrorMessage"]),
    // use the composed show error message. We add it as part of the methods so that it gets the proper this variable
    showError,
    changeProjectID() {
      // updates visually the project id
      console.log(this.projectID);
      if (!isNaN(this.projectId) && !this.projectId == "") {
        this.existingProjectId = this.projects[this.projectId - 1].readableID;
      } else {
        this.currentProjectID = "Select a project";
      }
    },

    submitExistingProject: async function () {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });

      if (!this.projectID) {
        loadingInstance.close();
        this.showError("Please make sure to input a project id");
        return;
      }

      const existingProjectData = {
        projectID: this.existingProjectId,
      };

      try {
        let response = await apiservice.request(
          true,
          urls.DUPLICATE,
          existingProjectData
        );
        // actual response froom HTTP request
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          console.log(data);
          // Response from the backend
          if (data.statuscode === 200) {
            loadingInstance.close();
            this.$router.push({ name: "projectdetails", params:{id: this.projectID} });
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        loadingInstance.close();
        console.log(error.message);
        if (error.message.toLowerCase() == "failed to fetch") {
          this.showError("Please check if you are connected to the internet");
        } else {
          this.showError(error.message);
        }
      }
    },

    // filesChange(fieldName, fileList) {
    //   // handle file changes
    //   const formData = new FormData();

    //   if (!fileList.length) return;
    //   console.log(fieldName);
    //   // append the files to FormData
    // },
    submit: async function () {
       window.scrollTo(0,0);
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });

      // simulate AJAX
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const cleanedData = data.slice(6, data.length - 2);
          // let passedData = [];
          cleanedData.map((value) => {
            this.passedData.push({
              productimage: value[1],
              productname: value[1],
              desc: value[4],
              sku: value[2],
              quantity: value[6],
              unitprice: value[7],
              markup: 0,
              total: 0,
            });
          });
          // loadingInstance.close();
          this.$store.commit("setproductdata", this.passedData);
        };

        reader.readAsBinaryString(this.file);
      } else {
        window.scrollTo(0, 0);
        loadingInstance.close();
        setTimeout(() => {
          this.showError("please load in a file to proceed");
        }, 500)
        return;
      }

      let newProjectData = {
        projectTitle: this.newProjectName,
        buildingType: this.newProjectBuildingType,
        address: this.newProjectAddress,
        projectType: this.newProjectType,
        status: 'QUOTATION',
        clientName: this.newProjectClientName,
        clientPhone: this.newProjectClientPhone,
        clientEmail: this.newProjectClientMail,
      };
      // console.table(newProjectData);
      for(let key in newProjectData){

        if(!(newProjectData[key])){
          loadingInstance.close();
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.showError(`Ensure to fill in ALL the fields properly`)
          }, 500)
        return;
        }
        
      }

      try {
        let response = await apiservice.request(
          true,
          urls.NEWPROJECT,
          newProjectData
        );

        if (response.status === 200) {
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log({ data });
            loadingInstance.close();
            this.$router.push({
              name: "projectdetails",
              params: { id: data.data._id },
            });
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (e) {
        loadingInstance.close();
        window.scrollTo(0,0);
        console.log({ e });
        // simulate delay
        setTimeout(() => {

          this.showError(e.message);
        },500)
      }
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },

// for duplicating a project
async getAllPojects() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        let response = await apiservice.request(true, urls.ALLPROJECTS, {});
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log(data);
            // map over the project and get only the name and id
            this.projects = data.data.map((project) => ({
              id: project._id,
              name: project.projectTitle,
              readableID: project.projectID,
            }));
            loadingInstance.close();
          } else {
            throw new Error(data.message);
          }
        } else {
          loadingInstance.close();
          throw new Error(response.message);
        }
      } catch (err) {
        this.showError(err.message);
      }
    },
  },
  mounted() {
    this.getAllPojects();
  }
};
</script>

<style>
</style>