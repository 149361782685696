<!-- <template> -->
<!-- Page Container START -->
<div class="page-container">
  <!-- Content Wrapper START -->
  <div class="main-content">
    <div class="page-header no-gutters">
      <div class="row align-items-md-center col-md-12">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <div class="input-affix m-v-10">
                <i class="prefix-icon anticon anticon-search opacity-04"></i>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Contact List"
                  v-model="searchText"
                  @keyup="updateSearchText"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2" style="padding: 0px">
          <div class="m-v-10">
            <button
              class="btn btn-primary"
              style="
                    width: 100%;
                    max-width: 150px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
              @click.prevent="showContactDialog"
            >
              <i class="anticon anticon-plus"></i>
              <span class="font-size-12">Add new contact</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  
      <el-skeleton
        :loading="isLoading"
        animated
        :throttle="throttleSpeed"
      >
        <template #template>
          <div
          style="display: flex; flex-wrap: wrap; justify-content: flex-start; gap: 15px"
        >
        <div class="card"  style="width: 100%; max-width: 250px; " v-for="(phase, index) in filteredPhases">
          <div
            class="card-header"
            style="min-height: 2rem!important; display: flex; align-items: center; padding: 10px 0.5rem; justify-content: space-between;"
          >
            <el-skeleton-item
              variant="h3"
              style="padding-top: 0px!important; width: 60%; height: 20px;"
            >
            </el-skeleton-item>
            <div class="d-flex">
              <el-skeleton-item
                variant="rect"
                style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; margin-right: 5px;"
              >
              </el-skeleton-item>
              <el-skeleton-item
                variant="rect"
                style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; margin-right: 5px;"
              >
              </el-skeleton-item>
            </div>
          </div>
          <div class="card-body" style="padding: 1rem">
            <div class="d-flex flex-column align-items-start">
              <el-skeleton-item
                variant="rect"
                style="margin-bottom: 15px; width: 70%; height: 20px"
              >
              </el-skeleton-item>
              <el-skeleton-item variant="rect" style="width: 70%; height: 20px">
              </el-skeleton-item>
            </div>
          </div>
          </div>
        </div>
        </template>
        <template #default>
          <div
            style="display: flex; flex-wrap: wrap; justify-content: flex-start; gap: 15px"
          >
            <div
              style="width: 100%; max-width: 250px"
              title="phase.name"
              name="phase.id"
              v-if="filteredPhases.length >=1"
              v-for="(phase, index) in filteredPhases"
            >
              <div class="card" >
                <div
                  class="card-header"
                  style="min-height: 2rem!important; display: flex; align-items: center; padding: 10px 0.5rem; justify-content: space-between;"
                >
                  <p
                    class="font-size-14 font-weight-semibold"
                    style="padding-top: 0px!important;"
                  >
                    {{phase.name}}
                  </p>
                  <div class="d-flex">
                    <button
                    @click="deleteContact(index)"
                      class="btn btn-danger m-r-5"
                      style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center;"
                    >
                      <i class="prefix-icon anticon anticon-delete "></i>
                    </button>
                    <button
                    @click="editContact(index)"
                      class="btn btn-primary m-r-0"
                      style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center;"
                    >
                      <i class="prefix-icon anticon anticon-edit "></i>
                    </button>
                  </div>
                </div>
                <div class="card-body" style="padding: 1rem">
                  <div
                    class="d-flex"
                    style="display: flex; align-items: center"
                  >
                    <div class="">
                      <div class="d-md-flex align-items-center">
                        <div class="text-center text-sm-left  p-l-0">
                          <p class="font-size-12 m-b-5">
                            <span class="text-dark font-weight-bold"
                              >Email:</span
                            >
                            {{phase.email}}
                          </p>
                          <p class="font-size-12 m-b-5">
                            <span class="text-dark font-weight-bold">Phone</span
                            >: {{phase.phone}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="filteredPhases.length == 0">
              <p>No contact found</p>
            </div>
          </div>
        </template>
      </el-skeleton>

      <!-- New contact dialog -->
<el-dialog
title=""
v-model="isDialogDisplayed"
width="30%"
center>

  <h5 class="modal-title">Client Name</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Name" style="margin-bottom: 5px" v-model.trim="clientName">
  <h5 class="modal-title">Client Email</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Email" style="margin-bottom: 5px" v-model.trim="clientEmail">
  <h5 class="modal-title" >Client Phone</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Phone number" style="margin-bottom: 5px" v-model.trim="clientPhone">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="isDialogDisplayed = false">Cancel</el-button>
    <el-button type="primary" @click="addNewContact">Add Contact</el-button>
  </span>
</template>
</el-dialog>
      <!-- Edit contact dialog -->
<el-dialog
title=""
v-model="editDialogVisible"
width="30%"
center>

  <h5 class="modal-title">Client Name</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Name" style="margin-bottom: 5px" v-model.trim="newClientName">
  <h5 class="modal-title">Client Email</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Email" style="margin-bottom: 5px" v-model.trim="newClientEmail">
  <h5 class="modal-title" >Client Phone</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Phone number" style="margin-bottom: 5px" v-model.trim="newClientPhone">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="closeEditDialog">Cancel</el-button>
    <el-button type="primary" @click="confirmEditContact">Edit Contact</el-button>
  </span>
</template>
</el-dialog>

<!-- delete dialog -->

<el-dialog
  title=""
  v-model="deleteDialogVisible"
  width="30%"
  >
  <span>Are you sure you want to delete this</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click.prevent="closeDeleteDialog">Cancel</el-button>
      <el-button type="primary" @click="confirmDeleteContact">Confirm</el-button>
    </span>
  </template>
</el-dialog>
  </div>
  <!-- Content Wrapper END -->
</div>
