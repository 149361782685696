<template src="./projectdetails.html"></template>

<script>
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
import { ElLoading } from "element-plus";
import { mapActions, mapState, mapGetters } from "vuex";
import showError from "../../../composables/showError";
import apiservice from "../../../api/apiservice";
import urls from "../../../api/apiUrl";
export default {
  name: "projectdetails",
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
  props: ["id"],
  data() {
    return {
      activeName: 0,
      addProductDialog: false,
      showProductDialog: false,
      centerDialogVisible: false,
      showPhaseDialog: false,
      phaseDialogVisible: false,
      phaseTitle: "",
      newPhaseHasProduct: false,
      newprice: null,
      dialogVisible: false,
      taskdetails: "",
      focusedPhaseIndex: -1,
      focusedIndex: -1,
      parentFocusedIndex: -1,
      currentImage: {
        productimage:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        productname: "fgdfg",
        desc: "456456456",
        sku: "dgfdg456456",
        quantity: 0,
        unitprice: 0,
        markup: 0,
        total: 0,
      },
      percentage: "",
      phases: [
        {
          id: 0,
          title: "DESIGN SERVICES",
          hasPrice: false,
          price: 0,
          tasks: [
            { title: "Interior design & Space planning" },
            { title: "Furniture layout plan" },
            { title: "Consultation, Coordination & Project management" },
          ],
          expanded: true,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 1,
          title: "PRELIMINARIES",
          hasPrice: true,
          price: 2000,
          tasks: [
            { title: "Site protection during construction work" },
            { title: "Site disposal of construction rubbish " },
            { title: "Renovation Insurance " },
            { title: "Site application of renovation approval" },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 2,
          title: "GENERAL WORKS",
          hasPrice: true,
          price: 0,
          tasks: [
            { title: "Dismantling/Hacking works " },
            { title: "Wet works" },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 3,
          title: "CARPENTRY WORKS",
          hasPrice: true,
          price: 0,
          tasks: [
            {
              title:
                " Supply and install carpentry works c/w internal colored PVC finishes ",
            },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 4,
          title: "ELECTRICAL WORKS",
          hasPrice: true,
          price: 0,
          tasks: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          title: "PLUMBING WORKS",
          hasPrice: true,
          price: 0,
          tasks: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 5,
          title: "CUSTOM BUILD WORKS - KITCHEN",
          hasPrice: true,
          price: 0,
          tasks: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 6,
          title: "FURNITURES",
          hasProduct: true,
          hasPrice: true,
          price: 0,
          tasks: [],
          expanded: false,
          hasproduct: true,
          products: [],
          deleted: false,
        },
        {
          id: 7,
          title: "FURNISHING",
          hasProduct: true,
          hasPrice: true,
          price: 0,
          tasks: [],
          expanded: false,
          hasproduct: true,
          deleted: false,
        },
        {
          id: 8,
          title: "SUPPORT",
          hasPrice: false,
          price: 0,
          tasks: [
            { title: "Transportation, Deliveries & Installation" },
            { title: "Home Inspector Service" },
            { title: "Post Renovation Cleaning" },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
      ],
    };
  },
  computed: {
    filteredPhases() {
      return this.phases.filter((phase) => phase.deleted != true);
    },
    finalPhases() {
      return this.filteredPhases.map((phase) => {
        // clean up the data being sent back
        delete phase.id;
        delete phase.deleted;
        return phase;
      });
    },
  },
  methods: {
    ...mapGetters(["getpassedproducts"]),
    ...mapActions(["toggleError", "setErrorMessage"]),
    showError,
    deletePhase(index) {
      this.focusedPhaseIndex = index;
      this.phaseDialogVisible = true;
    },
    confirmDeletePhase() {
      this.phases[this.focusedPhaseIndex].deleted = true;
      this.phaseDialogVisible = false;
    },
    showloading: async function () {

      console.log(localStorage.getItem('BIDNG_TOKEN'));
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      console.log(this.filteredPhases);
      console.log(this.finalPhases);
      if (!this.percentage) {
        window.scrollTo(0, 0);
        loadingInstance.close();
        setTimeout(() => {
          this.showError("Please Enter only whole numbers from 1 to 100");
        }, 500);
      }

      let percentage = this.percentage / 100;

      let detailsData = {
        projectID: this.id,
        depositPercentage: percentage,
        phases: this.finalPhases,
      };

      try {
        let response = await apiservice.request(
          true,
          urls.PROJECTBREAKDOWN,
          detailsData
        );
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log(data);
            loadingInstance.close();
            this.$router.push({ path: "/schedule" });
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error);
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.showError(error.message);
        }, 500);
      }
    },
    addProduct(index) {
      this.addProductDialog = true;
      this.focusedIndex = index;
      console.log(this.focusedIndex);
    },
    addTask(index) {
      this.centerDialogVisible = true;
      this.focusedIndex = index;
      console.log(this.focusedIndex);
    },
    deleteTask(parentIndex, index) {
      this.dialogVisible = true;
      this.focusedIndex = index;
      this.parentFocusedIndex = parentIndex;
      console.log(this.focusedIndex);
    },
    confirmDeleteTask() {
      this.phases[this.parentFocusedIndex].tasks.splice(this.focusedIndex, 1);
      this.dialogVisible = false;
    },
    confirmAddTask() {
      console.log(this.taskdetails);
      this.phases[this.focusedIndex].tasks.push({ title: this.taskdetails });
      this.taskdetails = "";
      this.centerDialogVisible = false;
    },
    confirmAddPhase() {
      let previousPhase = this.phases[this.phases.length - 1];

      this.phases.push({
        // till we start having proper ids
        id: previousPhase.id + 1,
        title: this.phaseTitle,
        hasPrice: false,
        price: 0,
        tasks: [],
        expanded: false,
        hasproduct: this.newPhaseHasProduct,
        deleted: false,
      });

      this.phaseTitle = "";

      this.showPhaseDialog = false;
    },
    addPhase() {
      this.showPhaseDialog = true;
    },
    openprice(index) {
      console.log("in open price");
      this.focusedIndex = index;
      this.showProductDialog = true;
    },
    setprice(type) {
      if (type < 0) {
        this.phases[this.focusedIndex].hasPrice = false;
      } else {
        this.phases[this.focusedIndex].hasPrice = true;
        this.phases[this.focusedIndex].price = parseInt(this.newprice);
        this.newprice = null;
      }
      this.showProductDialog = false;
    },
  },
  mounted() {
    this.phases[8].products = this.$store.state.productlist;
    console.log(this.id);
  },
};
</script>

<style>
</style>
