<!-- <template> -->
    <!-- Page Container START -->
    <div class="page-container">
        <!-- Content Wrapper START -->
        <div class="main-content">

<div class="card">
    <div class="card-body">
        <div class="card-header">
            <h1 class="card-title text-center">Frequently Asked Questions</h1>
        </div>
        <div class="accordion borderless" id="accordion-borderless">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseOneBorderless">
                            <span>How do I pay for the Essentials or Premium plan</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseOneBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseTwoBorderless">
                            <span>Can I cancel my plan?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseTwoBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseThreeBorderless">
                            <span>Do I get a refund?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseThreeBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseTwoBorderless">
                            <span>Can I cancel my plan?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseTwoBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseThreeBorderless">
                            <span>Do I get a refund?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseThreeBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseTwoBorderless">
                            <span>Can I cancel my plan?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseTwoBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseThreeBorderless">
                            <span>Do I get a refund?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseThreeBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseTwoBorderless">
                            <span>Can I cancel my plan?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseTwoBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h5 class="card-title">
                        <a class="collapsed" data-toggle="collapse" href="#collapseThreeBorderless">
                            <span>Do I get a refund?</span>
                        </a>
                    </h5>
                </div>
                <div id="collapseThreeBorderless" class="collapse" data-parent="#accordion-borderless">
                    <div class="card-body">
                        ...
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
        </div>
        <!-- Content Wrapper END -->
      </div>
  