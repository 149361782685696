import URLS from "./apiUrl";
import { AuthError } from "../ErrorHandler";

const tokenKey = "BIDNG_TOKEN";
const userData = "USER";
const apiKey = process.env.VUE_APP_API_KEY;

function getToken() {
  // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI2MDVjNzA0NTI4NWVjMzAwMTNkMzc3OGEiLCJpYXQiOjE2MTY2NzA3ODl9.PNzHVLP_6m31-vlbzTMflGkfqmZsImiBpKvo05eCl3g';
  return localStorage.getItem(tokenKey);
}

function getUser() {
  return localStorage.getItem(userData);
}
function removeToken() {
  return localStorage.removeItem(tokenKey);
}
function setToken(token) {
  localStorage.setItem(tokenKey, token);
}
function setUser(user) {
  localStorage.setItem(userData, user);
}
const request = (hasToken = false, url, requestBody,) => {
  const token = getToken(); // get the token
  const headerDetails = {
    "Content-Type": "application/json",
    "x-api-key": "073640d1-dc2b-4be7-a240-a58a425fa792",
  };

  // check that token is available or api has token
  if (hasToken && token == null) {
    throw new AuthError("Unauthorized access, Please Login first");
  }
  // add token to header
  if (hasToken) {
    headerDetails["x-auth-token"] = token;
  }

  let postUrl;

  // set up the URL
  postUrl = URLS.BASEURL + url;

  return fetch(postUrl, {
    method: "post",
    headers: headerDetails,
    body: JSON.stringify(requestBody),
  });
};
function payStackInit(transactionObj) {
  const url = "https://api.paystack.co/transaction/initialize";
  const headers = {
    authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_SECRET}`,
    "content-type": "application/json",
    "cache-control": "no-cache",
  };

  return fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify(transactionObj),
  });
}
export default {
  request,
  getToken,
  setToken,
  setUser,
  getUser,
  payStackInit,
  removeToken,
};
