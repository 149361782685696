<template>
<!-- Page Container START -->
            <div class="page-container">
                

                <!-- Content Wrapper START -->
                <div class="main-content">
                    <div class="page-header">
                       
                        <ul class="steps">
                            <li class="step step-success">
                              <div class="step-content">
                                <span class="step-circle"><i class="anticon anticon-edit"></i></span>
                                <span class="step-text">Create</span>
                              </div>
                            </li>
                           
                            <li class="step ">
                              <div class="step-content">
                                <span class="step-circle"><i class="anticon anticon-book"></i></span>
                                <span class="step-text">Products</span>
                              </div>
                            </li>
                       
                          </ul>
                    

                        <!-- ending -->
                    </div>
                    <div class="card">
                        <div class="file-manager-wrapper">
                           
                            <div class="file-manager-content">
                               
                                <div class="file-manager-content-body">
                                    <div class="file-manager-content-files">
                                        <div class="unselect-bg"></div>
                                      
                            <h5>Add documents</h5>
                            <p>Check the document and file type</p>

                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile">
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                      </div>
                                             
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="inputState">Document type</label>
                                        <select id="inputState" class="form-control">
                                            <option selected>Select the document type</option>
                                            <option>BQ</option>
                                            <option>Quotation</option>
                                            <option>Contract</option>
                                            <option>Invoice</option>
                                            <option>VO</option>
                                        </select>
                                    </div>
                                </div>
                              
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-primary btn-block">Add file</button>
                                </div>
                                <div class="col-md-12">
                                    
                                    <router-link to="/new-project-4"  class="btn btn-primary btn-block">Next</router-link>
                                </div>
                            

                            </div>


                            
                                        <h5 class="m-t-60 relative">Files</h5>
                                        <div class="file-wrapper m-t-20">
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-pdf text-danger"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">App Flow.pdf</h6>
                                                    <span class="font-size-13 text-muted">19.8MB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-word text-primary"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Documentation.doc</h6>
                                                    <span class="font-size-13 text-muted">1.2MB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-excel text-success"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Expensess.xls</h6>
                                                    <span class="font-size-13 text-muted">518KB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-ppt text-secondary"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Presentation.ppt</h6>
                                                    <span class="font-size-13 text-muted">308KB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-word text-primary"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Guideline.doc</h6>
                                                    <span class="font-size-13 text-muted">1.2MB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-excel text-success"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Annual_Report.xls</h6>
                                                    <span class="font-size-13 text-muted">518KB</span>
                                                </div>
                                            </div>
                                            <div class="file vertical" >
                                                <div class="font-size-40">
                                                    <i class="anticon anticon-file-word text-primary"></i>
                                                </div>
                                                <div class="m-t-10">
                                                    <h6 class="mb-0">Design_brief.doc</h6>
                                                    <span class="font-size-13 text-muted">168KB</span>
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    
                                    <div class="file-manager-content-details">
                                        <div class="content-details d-none">
                                            <div class="p-h-25 p-v-15 d-flex justify-content-between align-items-center border-bottom">
                                                <h5 class="m-b-0">App Flow.pdf</h5>
                                                <div class="content-details-close">
                                                    <a class="text-dark" href="javascript:void(0);">
                                                        <i class="anticon anticon-right-circle"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="m-b-10">
                                                <div class="d-flex justify-content-around display-3 align-items-center content-details-file">
                                                    <i class="anticon anticon-file-pdf text-danger"></i>
                                                </div>
                                            </div>
                                            <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" data-toggle="tab" href="#tab-details">Details</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" data-toggle="tab" href="#tab-activity">Activity</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content m-t-15" id="myTabContent">
                                                <div class="tab-pane fade show active" id="tab-details">
                                                    <div class="p-h-25 p-v-15">
                                                        <dl class="row m-b-10">
                                                            <dt class="col-5 text-dark">Type:</dt>
                                                            <dd class="col-7">pdf</dd>
                                                        </dl>   
                                                        <dl class="row m-b-10">
                                                            <dt class="col-5 text-dark">Size:</dt>
                                                            <dd class="col-7">19.8MB</dd>
                                                        </dl>   
                                                        <dl class="row m-b-10">
                                                            <dt class="col-5 text-dark">Modified:</dt>
                                                            <dd class="col-7">Feb 17, 2019</dd>
                                                        </dl>   
                                                        <dl class="row m-b-10">
                                                            <dt class="col-5 text-dark">Created:</dt>
                                                            <dd class="col-7">Feb 17, 2019</dd>
                                                        </dl> 
                                                        <dl class="row m-b-10">
                                                            <dt class="col-5 text-dark">Author:</dt>
                                                            <dd class="col-7">Erin Gonzales</dd>
                                                        </dl>    
                                                    </div>
                                                    <div class="border-top border-bottom p-h-25 p-v-10 d-flex align-items-center">
                                                        <span class="text-dark font-weight-semibold m-r-10">Add Description</span> 
                                                        <button class="m-r-5 btn btn-icon btn-hover btn-rounded btn-sm">
                                                            <i class="anticon anticon-edit"></i>
                                                        </button>

                                                        <button class="m-r-5 btn btn-icon btn-hover btn-rounded btn-sm">
                                                            <i class="anticon anticon-delete"></i>
                                                        </button>
                                                    </div> 
                                                </div>
                                                <div class="tab-pane fade" id="tab-activity" role="tabpanel">
                                                    <div class="p-h-20 p-v-15">
                                                        <div class="m-b-25">
                                                            <div class="p-b-10 border-bottom">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="avatar avatar-image m-r-10">
                                                                        <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="text-gray">
                                                                        <span class="text-dark font-weight-semibold">Erin Gonzales </span>
                                                                        <span>added </span>
                                                                        <span>2 files</span>
                                                                        <div class="text-muted font-size-13">
                                                                            7:57PM
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul class="list-unstyled m-l-50 m-t-15">
                                                                    <li class="m-b-15">
                                                                        <div class="avatar avatar-icon avatar-red avatar-sm">
                                                                            <i class="anticon anticon-file-pdf"></i>
                                                                        </div>
                                                                        <span class="text-gray">Guide Line.pdf</span>
                                                                    </li>
                                                                    <li class="m-b-15">
                                                                        <div class="avatar avatar-icon avatar-blue avatar-sm">
                                                                            <i class="anticon anticon-file-word"></i>
                                                                        </div>
                                                                        <span class="text-gray">Business Plan.doc</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="m-b-25">
                                                            <div class="p-b-10 border-bottom">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="avatar avatar-image m-r-10">
                                                                        <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                                                    </div>
                                                                    <div class="text-gray">
                                                                        <span class="text-dark font-weight-semibold">Erin Gonzales </span>
                                                                        <span>added </span>
                                                                        <span>1 files</span>
                                                                        <div class="text-muted font-size-13">
                                                                            7:57PM
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul class="list-unstyled m-l-50 m-t-15">
                                                                    <li class="m-b-15">
                                                                        <div class="avatar avatar-icon avatar-cyan avatar-sm">
                                                                            <i class="anticon anticon-file-excel"></i>
                                                                        </div>
                                                                        <span class="text-gray">Expenses.xls</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-details-no-data">
                                            <div class="text-center">
                                                <img class="img-fluid opacity-04" src="assets/images/others/file-manager.png" alt="">
                                                <p class="text-muted m-t-20">Select folder or file to view details</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <!-- Content Wrapper END -->
            </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>