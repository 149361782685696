<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">
      <div class="container">
        <div class="card">
          <div class="card-body">
            <div id="invoice" class="p-h-30">
              <div class="m-t-15 invoice-title-block">
                <div class="inline-block">
                  <!-- <img
                    class="img-fluid invoice-image"
                    src="assets/images/logo/logo.png"
                    alt=""
                  /> -->
                  <address class="p-l-10">
                    <span class="font-weight-semibold text-dark"
                      >ABC Painting & Renovators</span
                    ><br />
                    <span class="address-text"
                      >123 Springvale Rd, Glen Waverly, Vic 3456,
                      Australia</span
                    ><br />
                    <span class="address-text"
                      >Tel: 08000000000, Fax: 08-0000-0000</span
                    ><br />

                    <span class="address-text"
                      >Website: www.wwwdotthatsall.com, Email:
                      sales@abc-painting.com</span
                    >
                  </address>
                </div>
                <div class="float-right">
                  <h2 class="address-title">QUOTATION</h2>
                  <p class="address-text address-title">
                    Biz Reg No: 1234-9876
                  </p>
                </div>
              </div>
              <div class="row m-t-20 address-row">
                <div class="col-sm-8 address-row-left">
                  <address class="p-l-10 m-t-10">
                    <span class="font-weight-semibold text-dark"
                      >Address</span
                    ><br />
                    <span class="address-text">12 Woodridge Rd</span><br />
                    <span class="address-text">Sunbury 3320</span><br />
                    <span class="address-text">Vic, Australia</span>
                  </address>
                </div>
                <div class="col-sm-4 address-row-right">
                  <div class="m-t-80">
                    <div>
                      <div class="address-flex">
                        <div class="address-text">Quotation No.</div>
                        <div class="address-text" style="font-weight: bold">
                          QT10000
                        </div>
                      </div>

                      <div class="address-flex">
                        <div class="address-text">Date</div>
                        <div class="address-text">26/06/2021</div>
                      </div>

                      <span class="address-text">Our Ref.</span><br />
                      <span class="address-text">Cust Ref</span><br />
                      <span class="address-text">Terms</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 greeting">
                <p class="address-title">Dear --, -----</p>
              </div>

              <div>
                <div class="col-sm-12 greeting-header">
                  <div class="description-header">
                    <p class="description-title">Description of Work</p>
                    <p class="description-amount">Amount</p>
                  </div>
                </div>
                <div class="col-sm-12 greeting-body">
                  <div class="description-message">
                    <p class="address-text">
                      Thank you for the opportunity to quote we are pleased to
                      quote as follows :
                    </p>
                    <p class="address-text">
                      Painting of the office unit at 12, Woodridge Rd. Price
                      includes
                    </p>
                    <ul>
                      <li>
                        <p class="address-text">All surface preparation</p>
                      </li>
                      <!-- <li></li> -->
                      <li>
                        <p class="address-text">
                          1 undercoat and 2 finishing coats to the color of your
                          choice
                        </p>
                      </li>
                      <!-- <li></li> -->
                      <li>
                        <p class="address-text">
                          Supply of paint and labour workmanship
                        </p>
                      </li>
                      <!-- <li></li> -->
                    </ul>
                  </div>
                  <div class="description-price">
                    <p class="address-text">RM 12,500.00</p>
                  </div>
                </div>
                <div class="col-sm-12 greeting-remarks">
                  <div class="remarks-message col-sm-9">
                    <p style="font-style: italic; font-weight: bold">Remarks</p>
                    <p style="font-style: italic" class="address-text">
                      PAYMENT TERMS: 30% deposit required to start work. Balance
                      70% on completion
                    </p>
                    <p style="font-style: italic" class="address-text">
                      VALIDITY: 90 days from the date of this quote
                    </p>
                    <p style="font-style: italic" class="address-text">
                      We trust that you will find our quote satisfactory and
                      look forward to working with you. Please contact us should
                      you have any questions at all.
                    </p>
                  </div>
                  <div class="remarks-price col-sm-3">
                    <div class="address-flex">
                      <div><p class="address-text">Tax</p></div>
                      <div><p class="address-text">RM 1,136.36</p></div>
                    </div>
                    <div class="address-flex">
                      <div>
                        <p class="address-text"><strong>Total</strong></p>
                      </div>
                      <div>
                        <p class="address-text"><strong>RM 12,500.00</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- First Description -->
              <!-- <div class="m-t-20">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Items</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>1</th>
                        <td>
                          Asus Zenfone 3 Zoom ZE553KL Dual Sim (4GB, 64GB)
                        </td>
                        <td>2</td>
                        <td>$450.00</td>
                        <td>$900.00</td>
                      </tr>
                      <tr>
                        <th>2</th>
                        <td>HP Pavilion 15-au103TX 15.6˝ Laptop Red</td>
                        <td>1</td>
                        <td>$550.00</td>
                        <td>$550.00</td>
                      </tr>
                      <tr>
                        <th>3</th>
                        <td>Canon EOS 77D</td>
                        <td>1</td>
                        <td>$875.00</td>
                        <td>$875.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row m-t-30 lh-1-8">
                  <div class="col-sm-12">
                    <div class="float-right text-right">
                      <p>Sub - Total amount: $2,325</p>
                      <p>vat (10%) : $232</p>
                      <hr />
                      <h3>
                        <span class="font-weight-semibold text-dark"
                          >Total :</span
                        >
                        $2,557.00
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="row m-t-30 lh-2">
                  <div class="col-sm-12">
                    <div class="border-bottom p-v-20">
                      <p class="text-opacity">
                        <small
                          >In exceptional circumstances, Financial Services can
                          provide an urgent manually processed special cheque.
                          Note, however, that urgent special cheques should be
                          requested only on an emergency basis as manually
                          produced cheques involve duplication of effort and
                          considerable staff resources. Requests need to be
                          supported by a letter explaining the circumstances to
                          justify the special cheque payment.</small
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row m-v-20">
                  <div class="col-sm-6">
                    <img
                      class="img-fluid text-opacity m-t-5"
                      width="100"
                      src="assets/images/logo/logo.png"
                      alt=""
                    />
                  </div>
                  <div class="col-sm-6 text-right">
                    <small
                      ><span class="font-weight-semibold text-dark"
                        >Phone:</span
                      >
                      (123) 456-7890</small
                    >
                    <br />
                    <small>support@themenate.com</small>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
  <!-- Page Container END -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.invoice-image {
  max-width: 200px;
}
.invoice-title-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.address-text {
  font-size: 12px;
}
p.address-text.address-title,
h2.address-title,
.invoice-title-block > .inline-block > address {
  margin: 0px;
}
.address-row,
.address-flex {
  display: flex;
  justify-content: space-between;
}
.address-row > .address-row-right > div {
  margin-top: 10px !important;
  margin-bottom: 10px;
}
.description-header {
  width: 100%;
  height: 30px;
  border-top: 2px solid black;
  border-bottom: 3px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description-title,
.description-amount {
  font-weight: bold;
  margin-bottom: 0px;
  color: black;
}
.greeting,
.greeting-header {
  padding-right: 0px;
  padding-left: 10px;
}
.greeting-body,
.greeting-remarks {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding-left: 0px;
  padding-right: 10px;
}
.greeting-body > div > p {
  margin-bottom: 5px;
}
.greeting-body {
  margin-top: 10px;

  padding-bottom: 30px;
  border-bottom: 2px solid black;
}
.greeting-body > div.description-price {
  margin-top: 30px;
}
.greeting-body > div > ul > li {
  margin-bottom: 8px;
  list-style: none;
}
.greeting-remarks > div > p,
.description-message > p,
.description-message > ul > li > p,
.description-price > p {
  color: black;
}
.remarks-price > div > div > p {
  margin-bottom: 0px;
  color: black;
}
.remarks-price {
  margin-top: 30px;
}
</style>