<template>
  <!-- Header START -->
  <div class="header">
    <div class="logo logo-dark">
      <a href="index.html">
        <img src="assets/images/logo/logo.png" alt="Logo" />
    
      </a>
    </div>
  
    <div class="nav-wrap">
      <ul class="nav-left">
        <li class="desktop-toggle">
          <a
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#modals"
          >
            <i class="anticon"></i>
          </a>
        </li>
        <li class="mobile-toggle">
          <a href="javascript:void(0);">
            <i class="anticon"></i>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#search-drawer"
          >
            <i class="anticon anticon-search"></i>
          </a>
        </li>
   
      </ul>
      <ul class="nav-right">
        <li class="dropdown dropdown-animated scale-left">
          <a href="javascript:void(0);" data-toggle="dropdown">
            <i class="anticon anticon-bell notification-badge"></i>
          </a>
          <div class="dropdown-menu pop-notification">
            <div
              class="
                p-v-15 p-h-25
                border-bottom
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p class="text-dark font-weight-semibold m-b-0">
                <i class="anticon anticon-bell"></i>
                <span class="m-l-10">Notification</span>
              </p>
              <a class="btn-sm btn-default btn" href="javascript:void(0);">
                <small>View All</small>
              </a>
            </div>
            <div class="relative">
              <div
                class="overflow-y-auto relative scrollable"
                style="max-height: 300px"
              >
                <a
                  href="javascript:void(0);"
                  class="dropdown-item d-block p-15 border-bottom"
                >
                  <div class="d-flex">
                    <div class="avatar avatar-blue avatar-icon">
                      <i class="anticon anticon-mail"></i>
                    </div>
                    <div class="m-l-15">
                      <p class="m-b-0 text-dark">You received a new message</p>
                      <p class="m-b-0"><small>8 min ago</small></p>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="dropdown-item d-block p-15 border-bottom"
                >
                  <div class="d-flex">
                    <div class="avatar avatar-cyan avatar-icon">
                      <i class="anticon anticon-user-add"></i>
                    </div>
                    <div class="m-l-15">
                      <p class="m-b-0 text-dark">New user registered</p>
                      <p class="m-b-0"><small>7 hours ago</small></p>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="dropdown-item d-block p-15 border-bottom"
                >
                  <div class="d-flex">
                    <div class="avatar avatar-red avatar-icon">
                      <i class="anticon anticon-user-add"></i>
                    </div>
                    <div class="m-l-15">
                      <p class="m-b-0 text-dark">System Alert</p>
                      <p class="m-b-0"><small>8 hours ago</small></p>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="dropdown-item d-block p-15"
                >
                  <div class="d-flex">
                    <div class="avatar avatar-gold avatar-icon">
                      <i class="anticon anticon-user-add"></i>
                    </div>
                    <div class="m-l-15">
                      <p class="m-b-0 text-dark">You have a new update</p>
                      <p class="m-b-0"><small>2 days ago</small></p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="dropdown dropdown-animated scale-left">
          <div class="pointer" data-toggle="dropdown">
            <div class="avatar avatar-image m-h-10 m-r-15">
              <img src="assets/images/avatars/thumb-3.jpg" alt="" />
            </div>
          </div>
          <div class="p-b-15 p-t-20 dropdown-menu pop-profile">
            <div class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50">
                <div class="avatar avatar-lg avatar-image">
                  <img src="assets/images/avatars/thumb-3.jpg" alt="" />
                </div>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">
                    Marshall Nichols
                  </p>
                  <p class="m-b-0 opacity-07">UI/UX Desinger</p>
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              class="dropdown-item d-block p-h-15 p-v-10"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i class="anticon opacity-04 font-size-16 anticon-user"></i>
                  <span class="m-l-10">Edit Profile</span>
                </div>
                <i class="anticon font-size-10 anticon-right"></i>
              </div>
            </a>
            <a
              href="javascript:void(0);"
              class="dropdown-item d-block p-h-15 p-v-10"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i class="anticon opacity-04 font-size-16 anticon-lock"></i>
                  <span class="m-l-10">Account Setting</span>
                </div>
                <i class="anticon font-size-10 anticon-right"></i>
              </div>
            </a>
            <a
              href="javascript:void(0);"
              class="dropdown-item d-block p-h-15 p-v-10"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i
                    class="anticon opacity-04 font-size-16 anticon-project"
                  ></i>
                  <span class="m-l-10">Projects</span>
                </div>
                <i class="anticon font-size-10 anticon-right"></i>
              </div>
            </a>
            <a
              href="/"
              class="dropdown-item d-block p-h-15 p-v-10"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <i class="anticon opacity-04 font-size-16 anticon-logout"></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i class="anticon font-size-10 anticon-right"></i>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="modal fade search" id="modals">
      <div class="modal-dialog special-modal">
        <div class="modal-content">
          <div class="modal-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from "vue";
import Bottom from "../components/Bottom.vue";

export default {
  name: "NavBar",
  methods: {
    open() {
      this.$msgbox({
        title: "Edit User Profile",
        customClass: "modal-box",
        message: h(
          "div",
          {
            class: "modal-body",
            style: "padding:0rem",
          },
          [
            h("div", { style: "width:50%; background:#fff; padding: 1rem;" }, [
              h(
                "div",
                {
                  class:
                    "avatar-flex margin-affix justify-content-center align-items-center",
                },
                [h("div", { class: "avatar-vue" }, "")]
              ),
              h("div", { class: "input-affix margin-affix" }, [
                h("i", { class: "prefix-icon anticon anticon-user" }, ""),
                h(
                  "input",
                  { class: "form-control", placeholder: "Username" },
                  ""
                ),
              ]),
              h(
                "div",
                {
                  class: "input-affix margin-affix",
                },
                [
                  h("i", { class: "prefix-icon anticon anticon-mail" }, ""),
                  h(
                    "input",
                    { class: "form-control", placeholder: "Email Address" },
                    ""
                  ),
                ]
              ),
              h(
                "div",
                {
                  class: "input-affix margin-affix",
                },
                [
                  h("i", { class: "prefix-icon anticon anticon-phone" }, ""),
                  h(
                    "input",
                    { class: "form-control", placeholder: "Phone Number" },
                    ""
                  ),
                ]
              ),
              h("div", { class: "justify-content-start align-items-start" }, [
                h("button", { class: "edit-save" }, "Save"),
              ]),
            ]),
            h("div", { style: "width:50%; background:#00c9a7" }, ""),
          ]
        ),

        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Loading...";
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
  },
};
</script>

<style lang="scss">
div.el-message-box__content {
  padding: 0px !important;
  height: calc(100% - 33px);
}
div.el-message-box__container,
div.el-message-box__message,
.modal-button,
.modal-body > div {
  height: 100%;
}
.modal-body {
  display: flex;
  height: 100% !important;
}
.el-message-box {
  max-width: 800px;
  width: 100% !important;
  height: 500px;
  border: none !important;
}
.avatar-flex {
  display: flex;
}

.edit-save {
  border-radius: 6px;
  max-width: 150px;
  width: 100%;
  height: 45px;
  background: #00c9a7;
  color: white;
  border: none;
  box-shadow: 0px;
  outline: none;
}

.avatar-vue {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #00c9a7;
  margin-top: 1rem;
}
.el-message-box__header {
  padding: 15px 15px !important;
  border-bottom: 1px solid #00c9a7;
}

.margin-affix {
  margin-bottom: 20px;
}

.margin-affix > input.form-control {
  padding: 0.7rem 2.5rem;
  border: 1px solid #00c9a7;
}

.margin-affix > input.form-control::placeholder {
  color: black;
}
</style>
<style scoped src="../css/main.css"></style>



// h("div", { class: "modal fade search", id: "modals" }, [
//           h("div", { class: "modal-dialog special-modal" }, [
//             h("div", { class: "modal-content" }, [
//               h(
//                 "div",
//                 {
//                   class: "modal-body",
//                 },
//                 [
//                   // h("div", { style: "width:50%; background:#fff" }, [
//                   h(
//                     "div",
//                     {
//                       class:
//                         "avatar-flex margin-affix justify-content-center align-items-center",
//                     },
//                     [h("div", { class: "avatar-vue" }, "")]
//                   ),
//                   h("div", { class: "input-affix margin-affix" }, [
//                     h("i", { class: "prefix-icon anticon anticon-user" }, ""),
//                     h("input", { class: "form-control" }, ""),
//                   ]),
//                   h("div", { class: "input-affix margin-affix" }, [
//                     h("i", { class: "prefix-icon anticon anticon-mail" }, ""),
//                     h("input", { class: "form-control" }, ""),
//                   ]),
//                   h("div", { class: "input-affix margin-affix" }, [
//                     h("i", { class: "prefix-icon anticon anticon-phone" }, ""),
//                     h("input", { class: "form-control" }, ""),
//                   ]),
//                   h(
//                     "div",
//                     { class: "justify-content-start align-items-start" },
//                     [h("button", { class: "edit-save" }, "Save")]
//                   ),
//                   // ]),
//                   // h("div", { style: "width:50%; background:#00c9a7" }, ""),
//                 ]
//               ),
//             ]),
//           ]),
//         ]),