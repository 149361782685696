<div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">

        <div class="row SubscriptionsPage">
            <div class="col-md-6 col-lg-4">
                <div class="card-body card">
                    <div class="media align-items-center">
                        <div class="m-l-10 ">
                            <p class="m-b-0 text-muted w-100 font-size-13">TOTAL SUBSCRIBED MEMBERS</p>
                            <h2 class="m-b-0 font-size-30">133</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="m-l-10 ">
                                <p class="m-b-0 text-muted w-100 font-size-13">TOTAL PROJECTS COMPLETED</p>
                                <h2 class="m-b-0 font-size-30">64</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="media align-items-center">
                            <div class="m-l-10 ">
                                <p class="m-b-0 text-muted w-100 font-size-13">TOTAL PAYMENTS RECEIVED</p>
                                <h2 class="m-b-0 font-size-30">149</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="page-header no-gutters">
                            <div class="row align-items-md-center">
                                <div class="col-md-4">
                                    <div class="row">
                                        <h4 class="m-b-10">All Members</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-md-center d-flex">
                                <div class="col-lg-8 row">
                                    <input type="text" class="form-control col-md-9 col-lg-9 m-r-5"
                                        placeholder="Search Subscribers" v-model="taskdetails">
                                    <button id="list-view-btn btn-primary" type="button"
                                        class="btn btn-primary btn-icon m-r-5" data-toggle="tooltip"
                                        data-placement="bottom" title="Search">
                                        <i class="anticon anticon-search"></i>
                                    </button>
                                    <button id="list-view-btn" type="button" class="btn btn-default btn-icon"
                                        data-toggle="tooltip" data-placement="bottom" title="Filter">
                                        <i class="anticon anticon-filter"></i>
                                    </button>
                                </div>
                                <!-- <router-link to="/new-project">
                                        <button class="btn btn-primary" style="margin-left: 5px;">
                                            <i class="anticon anticon-chart"></i>
                                            <span class="m-l-5">Generate Receipt</span>
                                        </button>
                                    </router-link> -->
                            </div>
                        </div>

                        <div class="container-fluid">
                            <div id="card-view">
                                <div class="row">
                                    <div class="table-responsive FinanceTable">
                                        <table class="table table-hover">
                                            <thead class="align-items-center">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Total Projects</th>
                                                    <th>Amount Received</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="align-items-center">
                                                    <td>
                                                        <div class="media align-items-center">
                                                            <span>James Cameron</span>
                                                        </div>
                                                    </td>
                                                    <td>3</td>
                                                    <td>RM130,420</td>
                                                    <td>
                                                        <button id="list-view-btn" type="button"
                                                            class="btn btn-primary btn-icon m-r-5" data-toggle="tooltip"
                                                            data-placement="bottom" title="View User">
                                                            <i class="anticon anticon-eye"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">Blocked Subscribers</h5>
                            <a href="" class="btn btn-default btn-sm">View All</a>
                        </div>
                        <div class="m-t-30">
                            <div class="d-flex m-b-20">
                                <div>
                                    <div class="avatar avatar-image avatar-lg">
                                        <img src="assets/images/avatars/thumb-7.jpg" alt="">
                                    </div>
                                </div>
                                <div class="m-l-20">
                                    <h5 class="m-b-0 text-dark font-size-15">Nuno Esipirito</h5>
                                </div>
                                <div class="m-l-20">
                                    <a href="" class="btn btn-red btn-sm">Unblock</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- Content Wrapper END -->

    <!-- Footer START -->
    <footer class="footer">
        <div class="footer-content">
            <p class="m-b-0">Copyright © 2019 Theme_Nate. All rights reserved.</p>
            <span>
                <a href="" class="text-gray m-r-15">Term &amp; Conditions</a>
                <a href="" class="text-gray">Privacy &amp; Policy</a>
            </span>
        </div>
    </footer>
    <!-- Footer END -->

</div>
<!-- Page Container END -->