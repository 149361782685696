<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">
      <div class="page-header">
 <div class="card">
        <div class="card-body">
          <el-steps :active="2" process-status="wait" finish-status="success" simple > 
              
  <el-step title="Create" icon="el-icon-edit"  finish-status="success" ></el-step>
  <el-step title="Project Details" icon="el-icon-s-claim" finish-status="success" ></el-step>
    <el-step title="Schedule" icon="el-icon-date"></el-step>
  <el-step title="Quotation" icon="el-icon-tickets"></el-step>
  </el-steps>
</div>
  </div>
     

        <!-- ending -->
      </div>
      <div class="card">
        <div class="card-body">
          <p>Failed to load chart, Purchase subscription</p>

 
          <br />
          <button @click.prevent="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
</template>

<script>

import { ElLoading } from 'element-plus';

export default {
   data() {
      return {
          dialogImageUrl: '',
        dialogVisible: false,
        imageUrl: '',
      }
    },
 methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
   handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!')
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!')
        }
        return isJPG && isLt2M
      },
            submit() {
               let loadingInstance = ElLoading.service({ fullscreen: true ,background :"rgba(0, 0, 0, 0.8)"})
                // simulate AJAX
                setTimeout(() => {
                     loadingInstance.close();
                     this.$router.push({ path: '/quotation' });
                }, 5000)
            },
            onCancel() {
                console.log('User cancelled the loader.');
            }
        },
};
</script>

<style>
</style>