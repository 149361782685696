<div
    class="container-fluid p-h-0 p-v-20 bg full-height d-flex"
    style="background-image: url('assets/images/others/login-3.png')"
  >
    <div class="d-flex flex-column justify-content-between w-100">
      <div class="container d-flex h-100">
        <div class="row align-items-center w-100">
          <div class="col-md-7 col-lg-5 m-h-auto">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center justify-content-between m-b-20">
                 
                 <br><br><br><br>
                </div>
                <form>
                  <div class="form-group">
                    <label class="font-weight-semibold" for="Email"
                      >Email:</label>
                    <div class="input-affix">
                      <i class="prefix-icon anticon anticon-user"></i>
                      <input type="email" class="form-control" id="Email" v-model="email" placeholder="Email"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="font-weight-semibold" for="password"
                      >Password:</label>
                    <!-- Formerly Forgot Password Aligned Right <a class="float-right font-size-13 text-muted" href="">Forgot Password?</a>-->
                    <el-input type="password" id="password" v-model="password" placeholder="Password" show-password>
                    <template #prefix>
                      <i class="prefix-icon anticon anticon-lock"></i>
                    </template>
                    </el-input>
                  </div>
                  <div class="m-b-20">
                    <a class="font-size-13 text-muted" href="">Forgot Password?</a>
                  </div>
                  <div class="form-group">
                    <button @click.prevent="login" class="wide_btn btn btn-primary mb-2">Sign In</button>
                    <div class="d-flex flex-column align-items-center justify-content-between">
                      <span class="font-size-13 text-muted">
                        Don't have an account?
                        <router-link class="" to="/register"> Signup</router-link>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-flex p-h-40 justify-content-between">
        <span class="">© 2019 ThemeNate</span>
        <ul class="list-inline">
          <li class="list-inline-item">
            <a class="text-dark text-link" href="">Legal</a>
          </li>
          <li class="list-inline-item">
            <a class="text-dark text-link" href="">Privacy</a>
          </li>
        </ul>
      </div>
    </div>
  </div>