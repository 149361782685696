<div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">

         <div class="GetHelpPage">
            <div class="card">
                <div class="col-md-12 col-lg-12">
                    <div class="info">
                        <div class="card-body">
                            <div class="m-l-10 ">
                                <h2 class="m-b-10">Need any help?</h2>
                                <p class="m-b-0 text-muted w-100">Provide the details below of how we may help you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="card">
                <div class="col-md-12 col-lg-12">
                    <div class="info">
                        <div class="card-body">
                            <div class="m-l-10 ">
                                <h4 class="m-b-30">Complaint Details</h4>
                            </div>
                            <div class="m-l-10">
                                <div class="btn-group d-flex flex-column m-b-30">
                                    <h5 class="m-b-10">Project ID</h5>
                                    <input type="text" class="form-control col-md-6 col-lg-6" placeholder="Enter the Project ID" v-model="taskdetails">
                                </div>
    
                                <div class="m-t-20 m-b-30">
                                    <h5 class="modal-title m-b-10" id="exampleModalCenterTitle">What would you like to talk about?</h5>
                                    <div class="dropdown">
                                        <button class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                            <span>Choose a contact</span>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">
                                                <span>Project</span>
                                            </a>
                                            <a class="dropdown-item" href="#">
                                                <span>Client</span>
                                            </a>
                                            <a class="dropdown-item" href="#">
                                                <span>Complaint</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="btn-group d-flex flex-column m-b-30">
                                    <h5 class="m-b-10">Description</h5>
                                    <textarea class="form-control w-70" id="textAreaExample1" rows="7"></textarea>
                                </div>
    
                                <div class="btn-group d-flex flex-column m-b-40">
                                    <h5 class="m-b-10">Upload any Documents</h5>
                                    <div class="d-flex">
                                        <input type="text" class="form-control col-md-5 col-lg-5 m-r-5" placeholder="Click 'Browse' button to upload" v-model="taskdetails">
                                        <button class="btn btn-default btn-primary" data-toggle="dropdown">
                                            <span>Browse</span>
                                        </button>
                                    </div>
                                </div>
    
                                <div class="m-t-20 m-b-20">
                                    <div class="button m-t-20 m-b-20">
                                        <button class="btn btn-default btn-primary col-lg-3" data-toggle="dropdown">
                                            <span>Send Message</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Content Wrapper END -->

    <!-- Footer START -->
    <footer class="footer">
        <div class="footer-content">
            <p class="m-b-0">Copyright © 2019 Theme_Nate. All rights reserved.</p>
            <span>
                <a href="" class="text-gray m-r-15">Term &amp; Conditions</a>
                <a href="" class="text-gray">Privacy &amp; Policy</a>
            </span>
        </div>
    </footer>
    <!-- Footer END -->

</div>
<!-- Page Container END -->