<template src="./Dashboard.html">
 
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.card.info{
  transition: all 0.3s ease;
}
.card.info:hover{
  transform: translateY(-5px);
  box-shadow: 0 3px 3px rgba(0,0,0,0.15);
}
</style>