import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';


const app = createApp(App).use(store).use(router).use(VCalendar, {})

app.use(ElementPlus)

app.config.globalProperties.$filters = {
  currencyUSD(value) {
    return `RM ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }
}


app.mount('#app')