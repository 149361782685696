<template src="./Contactlist.html"></template>

<script>
import { ElLoading } from "element-plus";
import { mapActions, mapState, mapGetters } from "vuex";
import apiService from "../../api/apiservice";
import urls from "../../api/apiUrl";
import showError from "../../composables/showError";

export default {
  name: "Contactlist",
  components: {},
  data() {
    return {
      isLoading: false,
      isDialogDisplayed: false,
      clientName: "",
      clientEmail: "",
      clientPhone: "",
      newClientName: "",
      newClientEmail: "",
      newClientPhone: "",
      searchText: "",
      throttleSpeed: 1500,
      phases: [],
      currentFocusedIndex: null,
      deleteDialogVisible: false,
      editDialogVisible: false,
    };
  },
  computed: {
    filteredPhases() {
      console.log(this.searchText);
      console.log(this.phases);
      return this.phases.filter((phase) =>
        phase.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
    this.refreshScreenData();
    loadingInstance.close();
  },
  methods: {
    ...mapActions(["toggleError", "setErrorMessage"]),
    showError,
    showContactDialog() {
      this.isDialogDisplayed = true;
    },

    showDeleteDialog() {
      this.deleteDialogVisible = true;
    },
    closeDeleteDialog() {
      this.deleteDialogVisible = false;
    },
    showEditDialog() {
      this.editDialogVisible = true;
    },
    closeEditDialog() {
      this.editDialogVisible = false;
    },
    toggleIsLoading() {
      this.isLoading = !this.isLoading;
    },
    cleanupFields() {
      this.clientName = "";
      this.clientEmail = "";
      this.clientPhone = "";
      this.newClientName = "";
      this.newClientEmail = "";
      this.newClientPhone = "";
    },
    async confirmDeleteContact() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      console.log(this.currentFocusedIndex);
      try {
        let reqData = {
          contactID: this.phases[this.currentFocusedIndex].id,
        };
        let response = await apiService.request(
          true,
          urls.DELETECONTACT,
          reqData
        );
        if (response.status === 200) {
          let data = await response.json();
          if (data.statuscode === 200) {
            loadingInstance.close();
            this.closeDeleteDialog();
            this.refreshScreenData();
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error.message);
        loadingInstance.close();
        this.closeDeleteDialog();
        this.showError(error.message);
      }
    },
    async confirmEditContact() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      console.log(this.currentFocusedIndex);
      try {
        let reqData = {
          contactID: this.phases[this.currentFocusedIndex].id,
          clientName: this.newClientName,
          clientEmail: this.newClientEmail,
          clientPhone: this.newClientPhone,
        };
        let response = await apiService.request(
          true,
          urls.UPDATECONTACT,
          reqData
        );
        if (response.status === 200) {
          let data = await response.json();
          if (data.statuscode === 200) {
            loadingInstance.close();
            this.closeEditDialog();
            this.refreshScreenData();
            this.cleanupFields();
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error.message);
        loadingInstance.close();
        this.closeEditDialog();
        this.showError(error.message);
      }
    },
    deleteContact(index) {
      this.currentFocusedIndex = index;
      this.showDeleteDialog();
      console.log(index);
      console.log(this.phases[index]);
    },
    editContact(index) {
      this.currentFocusedIndex = index;
      this.showEditDialog();
      let currentPhase = this.phases[index];
      this.newClientName = currentPhase.name;
      this.newClientEmail = currentPhase.email;
      this.newClientPhone = currentPhase.phone;
      console.log(this.phases[index]);
    },
    async refreshScreenData() {
      this.toggleIsLoading();
      try {
        let response = await apiService.request(true, urls.GETALLCONTACTS, {});
        if (response.status === 200) {
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log("now we are refreshed");
              this.toggleIsLoading();
            this.phases = data.data.map((data) => {
              return {
                name: data.clientName,
                email: data.clientEmail,
                phone: data.clientPhone,
                id: data._id,
              };
            });
            console.log(data);
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error.message);
        this.showError(error.message);
      }
    },

    closeDialog() {
      this.isDialogDisplayed = false;
    },
    addNewContact: async function () {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      console.log(this.clientName);
      console.log(this.clientPhone);
      console.log(this.clientEmail);

      const requestData = {
        clientName: this.clientName,
        clientEmail: this.clientEmail,
        clientPhone: this.clientPhone,
      };
      try {
        let response = await apiService.request(
          true,
          urls.ADDCONTACT,
          requestData
        );
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            this.closeDialog();
            loadingInstance.close();
            this.refreshScreenData();
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        console.log(error.message);
        this.showError(error.message);
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.014);
}
.card {
  transition: all 0.3s ease;
}
.card:hover {
  transform: translateY(-2.5px);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.089);
}
</style>
