<!-- <template> -->
<!-- Page Container START -->
<div class="page-container">
  <!-- Content Wrapper START -->
  <div class="main-content">
    <div class="page-header no-gutters">
      <div class="row align-items-md-center col-md-12">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <div class="input-affix m-v-10">
                <i class="prefix-icon anticon anticon-search opacity-04"></i>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Contact List"
                
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2" style="padding: 0px">
          <div class="m-v-10">
            <button
              class="btn btn-primary"
              style="
                    width: 100%;
                    max-width: 150px;
                    padding-left: 0px;
                    padding-right: 0px;
                  "
              @click.prevent="createReceipt"
            >
              <i class="anticon anticon-plus"></i>
              <span class="font-size-12">Generate Receipt</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  
    
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="1200">
        <el-table-column
          fixed
          prop="name"
          label="Project Name"
          width="260">
        </el-table-column>
        <el-table-column
          prop="id"
          label="Receipt ID"
          width="170">
        </el-table-column>
        <!-- <el-table-column
          prop="name"
          label="Client Name"
          width="150">
        </el-table-column> -->
        <el-table-column
          prop="projectID"
          label="Project ID"
          width="170">
        </el-table-column>
        <el-table-column
          prop="date"
          label="Date"
          width="170">
        </el-table-column>
        <el-table-column
          prop="amountPaid"
          label="Amount Paid"
          width="150">
        </el-table-column>
        <el-table-column
          prop="amountLeft"
          label="Amount Left"
          width="150">
        </el-table-column>
        <el-table-column
          prop="paymentMode"
          label="Payment Mode"
          width="250">
        </el-table-column>
        <!-- <el-table-column
          prop="productName"
          label="Project ID"
          width="350">
        </el-table-column> -->
        <el-table-column
          
          label="Operations"
          width="200">
          <template #default="scope">
            <el-button
              @click.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small">
              Download
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- New contact dialog -->
<!-- <el-dialog
title=""
v-model="isDialogDisplayed"
width="30%"
center>

  <h5 class="modal-title">Client Name</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Name" style="margin-bottom: 5px" v-model.trim="clientName">
  <h5 class="modal-title">Client Email</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Email" style="margin-bottom: 5px" v-model.trim="clientEmail">
  <h5 class="modal-title" >Client Phone</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Phone number" style="margin-bottom: 5px" v-model.trim="clientPhone">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="isDialogDisplayed = false">Cancel</el-button>
    <el-button type="primary" @click="addNewContact">Add Contact</el-button>
  </span>
</template>
</el-dialog> -->
      <!-- Edit contact dialog -->
<!-- <el-dialog
title=""
v-model="editDialogVisible"
width="30%"
center>

  <h5 class="modal-title">Client Name</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Name" style="margin-bottom: 5px" v-model.trim="newClientName">
  <h5 class="modal-title">Client Email</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Email" style="margin-bottom: 5px" v-model.trim="newClientEmail">
  <h5 class="modal-title" >Client Phone</h5>
<input type="text" class="form-control mb-3" placeholder="Enter the Client Phone number" style="margin-bottom: 5px" v-model.trim="newClientPhone">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="closeEditDialog">Cancel</el-button>
    <el-button type="primary" @click="confirmEditContact">Edit Contact</el-button>
  </span>
</template>
</el-dialog> -->

<!-- delete dialog -->

<!-- <el-dialog
  title=""
  v-model="deleteDialogVisible"
  width="30%"
  >
  <span>Are you sure you want to delete this</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click.prevent="closeDeleteDialog">Cancel</el-button>
      <el-button type="primary" @click="confirmDeleteContact">Confirm</el-button>
    </span>
  </template>
</el-dialog> -->
  </div>
  <!-- Content Wrapper END -->
</div>
