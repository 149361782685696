<template src="./Projects.html">
  
</template>

<script>
  export default {
     methods: {
        submit() {
         this.$router.push({ path: "/fulldetails" });
    },
     }
  }
</script>

<style lang="scss" scoped>
.card{
  transition: all 0.3s ease;
}
.card:hover{
  transform: translateY(-5px);
  box-shadow: 0 3px 3px rgba(0,0,0,0.15);
}
</style>