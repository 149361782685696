<template src='./Home.html'></template>

<script>
import { ElLoading } from "element-plus";
import {mapActions} from 'vuex';
import apiService from "../../api/apiservice";
import urls from "../../api/apiUrl";

import showError from '../../composables/showError'
export default {
  name: "Home",
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
    };
  },
  watch: {
    Email() {
      console.log("changed");
    },
  },
  methods: {
    showError,
    ...mapActions(['toggleError', 'setErrorMessage']),
    login: async function () {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {

        const reqData = {
          email: this.email,
          password: this.password,
        };
        const res = await apiService.request(false, urls.LOGIN, reqData,);
        const result = await res.json();
        if (result.statuscode !== 200) {
          loadingInstance.close();
          throw new Error(result.message);
        }
        apiService.setUser(JSON.stringify(result.data));
        apiService.setToken(result.data.token); // save the tokem
        loadingInstance.close();
        this.$router.replace({ name: "Dashboard" });
      } catch (err) {
        loadingInstance.close();
        this.showError(err.message);
        console.log(err.message);
      }
    },
  },
};
</script>
