<template src="./fulldetails.html"></template>

<script>
// import {
//   VueCollapsiblePanelGroup,
//   VueCollapsiblePanel,
// } from "@dafcoe/vue-collapsible-panel";
// import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
import { ElLoading } from "element-plus";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "fulldetails",
  components: {
    // VueCollapsiblePanelGroup,
    // VueCollapsiblePanel,
  },
  data() {
    return {
      activeName: 0,
      addProductDialog: false,
      showProductDialog: false,
      centerDialogVisible: false,
      showPhaseDialog: false,
      phaseDialogVisible: false,
      phaseTitle: "",
      phaseIndex: [],
      phaseUpdate: [],
      newPhaseHasProduct: false,
      newprice: null,
      dialogVisible: false,
      taskdetails: "",
      focusedPhaseIndex: -1,
      focusedIndex: -1,
      parentFocusedIndex: -1,
      currentImage: {
        productimage:
          "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
        productname: "fgdfg",
        desc: "456456456",
        sku: "dgfdg456456",
        quantity: 0,
        unitprice: 0,
        markup: 0,
        total: 0,
      },
      phases: [
        {
          id: 0,
          title: "DESIGN SERVICES",
          hasPrice: false,
          price: 0,
          tasks: [
            { title: "Interior design & Space planning" },
            { title: "Furniture layout plan" },
            { title: "Consultation, Coordination & Project management" },
          ],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: true,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 1,
          title: "PRELIMINARIES",
          hasPrice: true,
          price: 2000,
          tasks: [
            { title: "Site protection during construction work" },
            { title: "Site disposal of construction rubbish " },
            { title: "Renovation Insurance " },
            { title: "Site application of renovation approval" },
          ],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 2,
          title: "GENERAL WORKS",
          hasPrice: true,
          price: 0,
          tasks: [
            { title: "Dismantling/Hacking works " },
            { title: "Wet works" },
          ],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 3,
          title: "CARPENTRY WORKS",
          hasPrice: true,
          price: 0,
          tasks: [
            {
              title:
                " Supply and install carpentry works c/w internal colored PVC finishes ",
            },
          ],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 4,
          title: "ELECTRICAL WORKS",
          hasPrice: true,
          price: 0,
          tasks: [],
          panelMembers: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          title: "PLUMBING WORKS",
          hasPrice: true,
          price: 0,
          tasks: [],
          panelMembers: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 5,
          title: "CUSTOM BUILD WORKS - KITCHEN",
          hasPrice: true,
          price: 0,
          tasks: [],
          panelMembers: [],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
        {
          id: 6,
          title: "FURNITURES",
          hasProduct: true,
          hasPrice: true,
          price: 0,
          tasks: [],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Oluchi Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Nkiruka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: true,
          products: [],
          deleted: false,
        },
        {
          id: 7,
          title: "FURNISHING",
          hasProduct: true,
          hasPrice: true,
          price: 0,
          tasks: [],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Oluchi Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: true,
          deleted: false,
        },
        {
          id: 8,
          title: "SUPPORT",
          hasPrice: false,
          price: 0,
          tasks: [
            { title: "Transportation, Deliveries & Installation" },
            { title: "Home Inspector Service" },
            { title: "Post Renovation Cleaning" },
          ],
          panelMembers: [
            {
              title: "Chiamaka Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
            {
              title: "Chioma Nkem-Eze",
              image: "assets/images/avatars/thumb-1.jpg",
            },
          ],
          expanded: false,
          hasproduct: false,
          deleted: false,
        },
      ],
    };
  },
  computed: {
    filteredPhases() {
      return this.phases.filter((phase) => phase.deleted != true);
    },
  },
  methods: {
    ...mapGetters(["getpassedproducts"]),

    deletePhase(index) {
      this.focusedPhaseIndex = index;
      this.phaseDialogVisible = true;
    },
    confirmDeletePhase() {
      this.phases[this.focusedPhaseIndex].deleted = true;
      this.phaseDialogVisible = false;
    },
    showloading() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      // simulate AJAX
      setTimeout(() => {
        loadingInstance.close();
        this.$router.push({ path: "/schedule" });
      }, 5000);
    },
    addProduct(index) {
      this.addProductDialog = true;
      this.focusedIndex = index;
      console.log(this.focusedIndex);
    },
    addTask(phases) {
      this.centerDialogVisible = true;
      this.phaseIndex.tasks = JSON.parse(JSON.stringify(phases));
      this.phaseUpdate = this.phaseIndex.tasks.tasks;
      console.log(this.phaseIndex);
      console.log("Chi", this.phaseIndex.tasks.tasks);
      console.log(phases);
    },
    deleteTask(parentIndex, index) {
      this.dialogVisible = true;
      this.focusedIndex = index;
      this.parentFocusedIndex = parentIndex;
      console.log(this.focusedIndex);
    },
    confirmDeleteTask() {
      this.phases[this.parentFocusedIndex].tasks.splice(this.focusedIndex, 1);
      this.dialogVisible = false;
    },
    confirmAddTask() {
      console.log(this.taskdetails);
      this.phases[this.focusedIndex].tasks.push({ title: this.taskdetails });
      this.taskdetails = "";
      this.centerDialogVisible = false;
    },
    confirmAddPhase() {
      let previousPhase = this.phases[this.phases.length - 1];

      this.phases.push({
        // till we start having proper ids
        id: previousPhase.id + 1,
        title: this.phaseTitle,
        hasPrice: false,
        price: 0,
        tasks: [],
        expanded: false,
        hasproduct: this.newPhaseHasProduct,
        deleted: false,
      });

      this.phaseTitle = "";

      this.showPhaseDialog = false;
    },
    addPhase() {
      this.showPhaseDialog = true;
    },
    openprice(index) {
      console.log("in open price");
      this.focusedIndex = index;
      this.showProductDialog = true;
    },
    setprice(type) {
      if (type < 0) {
        this.phases[this.focusedIndex].hasPrice = false;
      } else {
        this.phases[this.focusedIndex].hasPrice = true;
        this.phases[this.focusedIndex].price = parseInt(this.newprice);
        this.newprice = null;
      }
      this.showProductDialog = false;
    },
  },
  mounted() {
    this.phases[8].products = this.$store.state.productlist;
  },
};
</script>

<style lang="scss" >
* {
  outline: none !important;
}
.el-dialog {
  max-height: 500px;
  height: 100%;
}
.el-card__body {
  padding: 5px !important;
}
.el-card.phases-card {
  padding: 0px 5px;
}
.card.phasescard,
.card.activities-card, 
.card.upload-card {
  overflow: scroll;
  max-height: 500px;
}
</style>
