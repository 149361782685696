<template src="./faq.html"></template>

<script>


export default {
  name: "Faq",

};
</script>

<style lang="scss" >
</style>
