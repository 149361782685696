<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">
      <div class="container">
        <div class="card">
          <div class="card-body">
            <h4>Generate a Reciept</h4>
            <p>Enter the details below to generate a reciept</p>

            <div class="m-t-25 col-sm-12 receipt-container">
              <div class="receipt-header">
                <h3 class="receipt-text">Total Amount Left</h3>
                <p class="receipt-price"> {{ formattedPrice }}</p>
              </div>
              <form class="receipt-form">
                <div class="form-group col-sm-11">
                  <label for="formGroupExampleInput">Project Name</label>
                  <!-- <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Project name"
                    v-model.trim="projectName"
                  /> -->
                  <select
                    v-model="projectId"
                    class="form-control"
                    style="min-width: 180px"
                    @change="changeProjectID(); getAmountLeft();"
                  >
                    <option selected value="">Select project</option>
                    <option
                      :value="index + 1"
                      v-for="(project, index) in projects"
                      :key="project.id"
                    >
                      {{ project.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-sm-11">
                  <label>Project ID</label>
                  <h3>{{ currentProjectID }}</h3>
                </div>

                <div class="form-group col-sm-11">
                  <label for="formGroupExampleInput">Amount Paid</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="amountPaid"
                    placeholder="Enter Amount Paid"
                  />
                  <p
                    style="
                      font-size: 8px;
                      font-style: italic;
                      margin-top: 5px;
                      margin-bottom: 0px;
                    "
                  >
                    *You need to input at least 70% to generate the receipt
                  </p>
                </div>

                <div class="form-group col-sm-11">
                  <label for="inputState">Payment Mode</label>
                  <select v-model.trim="paymentMode" class="form-control">
                    <option selected value="">Choose a payment mode</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Transfer">Transfer</option>
                    <option value="Cash">Cash</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="receipt-footer">
              <h3 class="receipt-text">Balance</h3>
              <p class="receipt-price">{{ balance }}</p>
            </div>

            <button @click="generateReceipt" class="btn btn-primary">
              Generate Reciept
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
  <!-- Page Container END -->
</template>

<script>
import { ElLoading } from "element-plus";
import { mapActions } from "vuex";
import showError from "../../composables/showError";
import apiservice from "../../api/apiservice";
import urls from "../../api/apiUrl";
export default {
  data() {
    return {
      amountPaid: 0,
      projectId: "",
      paymentMode: "",
      mainPrice: 0,
      projects: [],
      currentProjectID: "Select a project",
      amountLeft: "Select a Project"
    };
  },
  computed: {
    formattedPrice() {
      return "RM "+ this.formatNumber(this.mainPrice);
    },
    projectID() {
      if (!isNaN(this.projectId) && !this.projectId == "") {
        return this.projects[this.projectId - 1].id;
      }
    },
    projectName() {
      if (!isNaN(this.projectId) && !this.projectId == "") {
        return this.projects[this.projectId - 1].name;
      }
    },
    balance() {
      let result = this.mainPrice - this.amountPaid;
      if (result < 0) {
        return "You cannot pay more than the expected amount";
      }
      return "RM " + this.formatNumber(result);
    },
  },
  methods: {
    ...mapActions(["toggleError", "setErrorMessage"]),
    showError,
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    changeProjectID() {
      // updates visually the project id
      console.log(this.projectID);
      if (!isNaN(this.projectId) && !this.projectId == "") {
        this.currentProjectID = this.projects[this.projectId - 1].readableID;
      } else {
        this.currentProjectID = "Select a project";
      }
    },
    
    async getAmountLeft() {
      // console.log('Amount left guy');
      // console.log(this.projectID);
      if(this.projectID){
          let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      
      let reqData = {
        projectID: this.projectID
      }
      try {
        let response = await apiservice.request(true, urls.SINGLEPROJECT, reqData);
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            let {amountPaid, cost} =data.data;
            console.log("Amount paid",amountPaid);
            console.log("Cost",cost);
            this.mainPrice = cost - amountPaid;
            console.log(data);
            loadingInstance.close();
          } else {
            loadingInstance.close();
            throw new Error(data.message);
          }
        } else {
          loadingInstance.close();
          throw new Error(response.message);
        }
      } catch (err) {
        this.showError(err.message);
      }
      } else{
        return;
      }

    },
    async getAllPojects() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      try {
        let response = await apiservice.request(true, urls.ALLPROJECTS, {});
        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log(data);
            // map over the project and get only the name and id
            this.projects = data.data.map((project) => ({
              id: project._id,
              name: project.projectTitle,
              readableID: project.projectID,
            }));
            loadingInstance.close();
          } else {
            throw new Error(data.message);
          }
        } else {
          loadingInstance.close();
          throw new Error(response.message);
        }
      } catch (err) {
        this.showError(err.message);
      }
    },
    async generateReceipt() {
      // console.log(this.projectID);
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      // top to bottom check of inputs
      if ( !this.projectName || this.projectName.length === 0) {
        loadingInstance.close();
        window.scrollTo(0, 0);
        this.showError("Please enter a project name");
        return;
      }
      if (!this.paymentMode || this.paymentMode.length === 0) {
        loadingInstance.close();
        window.scrollTo(0, 0);
        this.showError("Please enter a payment mode");
        return;
      }
      // let percentageAccepted = this.mainPrice * 0.7;
      // if (this.amountPaid < percentageAccepted) {
      //   loadingInstance.close();
      //   this.showError(
      //     "Please Ensure that you pay at least 70% of the Recommended amount"
      //   );
      //   return;
      // }
      // end of check
      // api call related stuff here
      console.log(this.paymentMode);
      let reqData = {
        projectID: this.projectID,
        paymentMode: this.paymentMode,
        amountPaid: this.amountPaid,
      };

      console.log(reqData);
      try {
        let response = await apiservice.request(true, urls.GENERATERECEIPT, reqData);
        if(response.status === 200){
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            console.log(data);
            this.$router.push({path: "/receipt-list"})
            loadingInstance.close();
          }else{
            throw new Error(data.message);
          }
        }else{
          throw new Error(response.message);
        }
      } catch (err) {
        loadingInstance.close();
        console.log(err);
      }
    },
  },
  mounted() {
    this.getAllPojects();
  },
};
</script>

<style lang="scss" scoped>
.receipt-form {
  display: grid;
  grid-template-columns: 50% 50%;
}
.receipt-container {
  padding: 0px;
}
.receipt-container > .receipt-form > div {
  padding: 0px;
}
.receipt-text,
.receipt-price {
  color: #000;
  font-size: 14px;
}
.receipt-price {
  font-weight: bold;
  font-size: 28px;
}
.receipt-header,
.receipt-footer {
  width: 100%;
  border-top: 1px solid #ddd;
}
.receipt-footer {
  margin-top: 1rem;
  padding-top: 2rem;
}
.receipt-footer > h3 {
  margin-bottom: 0rem;
}
.receipt-header > h3 {
  margin-bottom: 0px;
  padding-top: 2rem;
}
.receipt-header > p,
.receipt-footer > p {
  margin-bottom: 2rem;
}
.card-body > h4,
.card-body > p {
  margin-bottom: 0.1rem;
}
</style>