export default  {
  setproductdata(state, data) {
    state.productlist = data;
  },

  toggleError(state) {
    state.isError = !state.isError;
  },

  setErrorMessage(state, data) {
    state.errorMessage = data;
  },
};