import { createStore } from "vuex";
import state from './state';
import mutations from "./mutations";
import actions from "./actions";
export default createStore({
  state,
  mutations,
  getters: {
    getpassedproducts: (state) => state.productlist,
    getErrorState: (state) => state.isError,
    getErrorMessage: (state) => state.errorMessage,
  },
  actions,
  modules: {},
});
