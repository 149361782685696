<template>
  <!-- Side Nav START -->
  <div class="side-nav">
    <div class="side-nav-inner">
      <ul class="side-nav-menu scrollable">
        <li class="nav-item dropdown open">
          <a class="dropdown-toggle" href="javascript:void(0);">
            <span class="icon-holder">
              <i class="anticon anticon-dashboard"></i>
            </span>
            <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
          </a>
        </li>


   <li class="nav-item dropdown open">
          <router-link class="dropdown-toggle" to="/project-list">
            <span class="icon-holder">
              <i class="anticon anticon-profile"></i>
            </span>
            <span>Projects</span>
          </router-link>
        </li>

        <li class="nav-item dropdown open">
          <router-link class="dropdown-toggle" to="/profile">
            <span class="icon-holder">
              <i class="anticon anticon-user"></i>
            </span>
            <span>Profile</span>
          </router-link>
        </li>
        <li class="nav-item dropdown open">
          <router-link class="dropdown-toggle" to="/contactlist">
            <span class="icon-holder">
              <i class="anticon anticon-usergroup-add"></i>
            </span>
            <span>Contacts</span>
          </router-link>
        </li>
        <li class="nav-item dropdown open">
          <router-link class="dropdown-toggle" to="/receipt-list">
            <span class="icon-holder">
              <i class="anticon anticon-credit-card"></i>
            </span>
            <span>Finance</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <!-- Side Nav END -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>