<div class="page-container">

    <!-- Content Wrapper START -->
    <div class="main-content">

        <div class="container">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-7">
                            <div class="d-md-flex align-items-center">
                                <div class="text-center text-sm-left ">
                                    <div class="avatar avatar-image" style="width: 150px; height:150px">
                                        <img src="assets/images/avatars/thumb-3.jpg" alt="">
                                    </div>
                                </div>
                                <div class="text-center text-sm-left m-v-15 p-l-30">
                                    <h2 class="m-b-5">Marshall Nichols</h2>
                                    <p class="text-opacity font-size-13">@Marshallnich</p>
                                    <p class="text-dark m-b-20">Design / Build</p>
                                    <button class="btn btn-primary m-r-5">
                                        <i class="anticon anticon-edit m-r-5"></i>
                                        <span>Edit profile</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="d-md-block d-none border-left col-1"></div>
                                <div class="col">
                                    <ul class="list-unstyled m-t-10">
                                        <li class="row">
                                            <p class="col-sm-8 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-info-circle"></i>
                                                <span>Account type </span>
                                            </p>
                                            <p class="col font-weight-semibold"> Free</p>
                                        </li>
                                        <li class="row">
                                            <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-phone"></i>
                                                <span>Expiration </span>
                                            </p>
                                            <p class="col font-weight-semibold"> Mon 23rd Jun 2020</p>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-lg-6 text-left">
                                    <h5>Company details</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <a href="" class="btn btn-primary">
                                        <i class="anticon anticon-plus-circle m-r-5"></i>
                                        <span>Edit</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col">
                                    <ul class="list-unstyled m-t-10">
                                        <li class="row">
                                            <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-mail"></i>
                                                <span>Email: </span>
                                            </p>
                                            <p class="col font-weight-semibold"> Marshall123@gmail.com</p>
                                        </li>
                                        <li class="row">
                                            <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-phone"></i>
                                                <span>Phone: </span>
                                            </p>
                                            <p class="col font-weight-semibold"> +12-123-1234</p>
                                        </li>
                                        <li class="row">
                                            <p class="col-sm-4 col-5 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-compass"></i>
                                                <span>Location: </span>
                                            </p>
                                            <p class="col font-weight-semibold"> Los Angeles, CA</p>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-lg-6 text-left">
                                    <h5>Contactdetails</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <a href="" class="btn btn-primary">
                                        <i class="anticon anticon-plus-circle m-r-5"></i>
                                        <span>Edit</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col">
                                    <ul class="list-unstyled m-t-10">
                                        <li class="row">
                                            <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-mail"></i>
                                                <span>Email: </span>
                                            </p>
                                            <p class="col font-weight-semibold"> Marshall123@gmail.com</p>
                                        </li>
                                        <li class="row">
                                            <p class="col-sm-4 col-4 font-weight-semibold text-dark m-b-5">
                                                <i class="m-r-10 text-primary anticon anticon-phone"></i>
                                                <span>Phone: </span>
                                            </p>
                                            <p class="col font-weight-semibold"> +12-123-1234</p>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-lg-6 text-left">
                                    <h5>Specialilty</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <a href="" class="btn btn-primary">
                                        <i class="anticon anticon-plus-circle m-r-5"></i>
                                        <span>Manage</span>
                                    </a>
                                </div>
                            </div>
                            <h5 class="m-t-20">
                                <span
                                    class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Sketch</span>
                                <span
                                    class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Marvel</span>
                                <span
                                    class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Photoshop</span>
                                <span
                                    class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Illustrator</span>
                                <span class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Web
                                    Design</span>
                                <span class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">Mobile App
                                    Design</span>
                                <span class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">User
                                    Interface</span>
                                <span class="badge badge-pill badge-default font-weight-normal m-r-10 m-b-10">User
                                    Experience</span>
                            </h5>
                            <hr>

                        </div>
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6 text-left">
                                    <h5>Users</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <a href="" class="btn btn-primary">
                                        <i class="anticon anticon-plus m-r-5"></i>

                                    </a>

                                </div>
                            </div>
                            <div class="m-t-30">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-image">
                                        <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                    </div>
                                    <div class="m-l-10">
                                        <h5 class="m-b-0">
                                            <a href="" class="text-dark">Erin Gonzales</a>
                                        </h5>
                                        <span class="font-size-13 text-gray">UI/UX Designer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-t-30">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-image">
                                        <img src="assets/images/avatars/thumb-2.jpg" alt="">
                                    </div>
                                    <div class="m-l-10">
                                        <h5 class="m-b-0">
                                            <a href="" class="text-dark">Darryl Day</a>
                                        </h5>
                                        <span class="font-size-13 text-gray">Software Engineer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-t-30">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-image">
                                        <img src="assets/images/avatars/thumb-3.jpg" alt="">
                                    </div>
                                    <div class="m-l-10">
                                        <h5 class="m-b-0">
                                            <a href="" class="text-dark">Marshall Nichols</a>
                                        </h5>
                                        <span class="font-size-13 text-gray">Product Manager</span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-t-30">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-image">
                                        <img src="assets/images/avatars/thumb-6.jpg" alt="">
                                    </div>
                                    <div class="m-l-10">
                                        <h5 class="m-b-0">
                                            <a href="" class="text-dark">Riley Newman</a>
                                        </h5>
                                        <span class="font-size-13 text-gray">Data Analyst</span>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <a href="" class="btn btn-primary btn-block">
                                <i class="anticon anticon-edit m-r-5"></i>
                                Manage users
                            </a>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6 text-left">
                                    <h5>Financial Info</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <a href="" class="btn btn-primary">
                                        <i class="anticon anticon-edit m-r-5"></i>

                                    </a>

                                </div>

                                <div class="m-t-30">
                                    <div class="d-flex align-items-center">

                                        <div class="m-l-10">
                                            <h5 class="m-b-0">
                                                <a href="" class="text-dark">Bank name</a>
                                            </h5>
                                            <span class="font-size-13 text-gray">Maybank</span>
                                        </div>


                                    </div>
                                </div>


                                <div class="m-t-20">
                                    <div class="m-l-10">
                                        <h5 class="m-b-0">
                                            <a href="" class="text-dark">Bank account details</a>
                                        </h5>
                                        <span class="font-size-13 text-gray">83495348593</span>
                                    </div>


                                    <div class="m-b-20 p-b-20 border-bottom">
                                        <div class="media align-items-center m-b-15">


                                        </div>

                                        <div class="float-right">

                                        </div>
                                    </div>
                                    <div class="m-b-20 p-b-20 border-bottom">
                                        <div class="media align-items-center m-b-15">
                                            <div class="col-lg-12 text-left">
                                                <h5>Person In Charge</h5>
                                            </div>
                                        </div>
                                        <p>Jenn Chan, Jenn@gmail.com 08037297405</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Content Wrapper END -->

        <!-- Footer START -->
        <footer class="footer">
            <div class="footer-content">
                <p class="m-b-0">Copyright © 2019 Theme_Nate. All rights reserved.</p>
                <span>
                    <a href="" class="text-gray m-r-15">Term &amp; Conditions</a>
                    <a href="" class="text-gray">Privacy &amp; Policy</a>
                </span>
            </div>
        </footer>
        <!-- Footer END -->

    </div>
    <!-- Page Container END -->

</div>