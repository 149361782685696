import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home/Home.vue";
import Register from "../views/Register/Register.vue";
import Receiptlist from "../views/Receiptlist/receiptlist.vue";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Projects from "../views/Projects/Projects.vue";
import Profile from "../views/Profile/Profile.vue";
import NewProject from "../views/New-Project/NewProject.vue";
import NewProject2 from "../views/New-Project/NewProject2.vue";
import NewProject3 from "../views/New-Project/NewProject3.vue";
import quotation from "../views/New-Project/quotation/quotation.vue";
import projectdetails from "../views/New-Project/projectdetails/projectdetails.vue";
import NewProject5 from "../views/New-Project/NewProject5.vue";
import NewProject4 from "../views/New-Project/NewProject4.vue";
import CreateReceipt from "../views/New-Project/CreateReceipt.vue";
import Schedule from "../views/New-Project/schedule/schedule";
import Fulldetails from "../views/fulldetails/fulldetails";
import ContactList from "../views/Contactlist/Contactlist";
import Faq from "../views/Faq/faq";
import Help from "../views/Help/Help";
import Subscription from "../views/Subscription/Subscription";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/project-list",
    name: "ProjectList",
    component: Projects,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/new-project",
    name: "NewProject",
    component: NewProject,
  },
  {
    path: "/new-project-2",
    name: "NewProject2",
    component: NewProject2,
  },
  {
    path: "/new-project-3",
    name: "NewProject3",
    component: NewProject3,
  },
  {
    path: "/projectdetails/:id",
    name: "projectdetails",
    props: true,
    component: projectdetails,
  },
  {
    path: "/new-project-5",
    name: "NewProject5",
    component: NewProject5,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
  },
  {
    path: "/quotation",
    name: "quotation",
    component: quotation,
  },
  {
    path: "/new-project-4",
    name: "NewProject4",
    component: NewProject4,
  },
  {
    path: "/create-receipt",
    name: "CreateReceipt",
    component: CreateReceipt,
  },
  {
    path: "/receipt-list",
    name: "Receiptlist",
    component: Receiptlist,
  },
  {
    path: "/schedule",
    name: "schedule",
    component: Schedule,
  },
  {
    path: "/fulldetails",
    name: "fulldetails",
    component: Fulldetails,
  },
  {
    path: "/contactlist",
    name: "contactlist",
    component: ContactList,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
