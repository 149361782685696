<template>
  <!-- Page Container START -->
            <div class="page-container">
                

                <!-- Content Wrapper START -->
                <div class="main-content">
                    <div class="page-header">
                       
                        <ul class="steps">
                            <li class="step step-success">
                              <div class="step-content">
                                <span class="step-circle"><i class="anticon anticon-edit"></i></span>
                                <span class="step-text">Create</span>
                              </div>
                            </li>
                            <li class="step step-active">
                              <div class="step-content">
                                <span class="step-circle"><i class="anticon anticon-calendar"></i></span>
                                <span class="step-text">Schedule</span>
                              </div>
                            </li>
                            <li class="step">
                              <div class="step-content">
                                <span class="step-circle"> <i class="anticon anticon-folder-open"></i> </span>
                                <span class="step-text">Documents</span>
                              </div>
                            </li>
                            <li class="step">
                              <div class="step-content">
                                <span class="step-circle"><i class="anticon anticon-book"></i></span>
                                <span class="step-text">Products</span>
                              </div>
                            </li>
                            <li class="step">
                                <div class="step-content">
                                  <span class="step-circle"><i class="anticon anticon-team"></i></span>
                                  <span class="step-text">Panel</span>
                                </div>
                              </li>
                          </ul>
                    

                        <!-- ending -->
                    </div>
                    
                    <div class="card">
                        <div class="card-body">
                           
                                   
                                        <div class="toast fade show">
                                            <div class="toast-header">
                                                <i class="anticon anticon-info-circle text-primary m-r-5"></i>
                                                <strong class="mr-auto">IDMan Tips</strong>
                                                <small>11 mins ago</small>
                                                <button type="button" class="ml-2 close" data-dismiss="toast" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="toast-body">
                                               The best pratice for projects name is to have residence name with combination and style. E.g SKYPOD MODERN CHAN
                                            </div>
                                        </div>
                                
                          
                            <div class="row">
                                <div class="col-md-6 col-lg-3">
                                    <div class="row">
                                        
                                        <div class="card">
                                            <div class="card-body">
                                                <router-link to="/new-project" class="btn btn-primary">Back</router-link>
                                                <br>
                                       <!-- Default Datepicker-->
                                       <h4>Schedule</h4><br>
                                       <p>Define the project schedule</p>
                                        <div class="input-affix m-b-10">
                                            <i class="prefix-icon anticon anticon-calendar"></i>
                                            <input type="text" class="form-control datepicker-input" placeholder="Kick off date">
                                        </div>
                                
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">

                                    <div class="row">
                                        <!-- Default Datepicker-->
                                        <h4>Phases</h4>
                               
            
                                    </div>

                                    <router-link to="/new-project-3" class="btn btn-primary">Next</router-link>
                                    <br>
                                </div>
                                     </div>
                                </div>
                                <div class="col-md-6 col-lg-9">
                                    <div class="card">
                                        <div class="card-body">
                                           Gannt plugin to be used during implementation, cannot be linked during design 
                                        </div>
                                    </div>
                                </div>
                              
                            </div>

                            

                        </div>
                    </div>
                  
                </div>
                <!-- Content Wrapper END -->
            </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>