  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">


<!-- Modal -->
<el-dialog
title=""
v-model="centerDialogVisible"
width="30%"
center>

<h5 class="modal-title" id="exampleModalCenterTitle">Enter new task</h5>
<input type="text" class="form-control" placeholder="Enter the task description" v-model="taskdetails">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="centerDialogVisible = false">Cancel</el-button>
    <el-button type="primary" @click="confirmAddTask()">Add Task</el-button>
  </span>
</template>
</el-dialog>

<!-- New phase dialog -->
<el-dialog
title=""
v-model="showPhaseDialog"
width="30%"
center>

<h5 class="modal-title" id="exampleModalCenterTitle">Enter new Phase</h5>
<input type="text" class="form-control" placeholder="Enter the Phase title" style="margin-bottom: 5px" v-model.trim="phaseTitle">
<el-checkbox  style="width:100%" v-model="newPhaseHasProduct" border>Has products?</el-checkbox>
<template #footer>
  <span class="dialog-footer">
    <el-button @click="showPhaseDialog = false">Cancel</el-button>
    <el-button type="primary" @click="confirmAddPhase">Add Phase</el-button>
  </span>
</template>
</el-dialog>

<el-dialog
title=""
v-model="showProductDialog"
width="60%"
center>

<h5 class="modal-title" id="showProductDialogCenterTitle">Enter new price</h5>
<input type="number" class="form-control" placeholder="Enter the new price" v-model="newprice">
<template #footer>
  <span class="dialog-footer">
    <el-button @click="showProductDialog = false">Cancel</el-button>
    <el-button type="primary" @click="setprice(-1)">Make Complimentary</el-button>
    <el-button type="primary" @click="setprice(0)">Save Price</el-button>
  </span>
</template>
</el-dialog>


<el-dialog
title=""
v-model="addProductDialog"
width="60%"
center
>

<h5 class="modal-title" id="showProductDialogCenterTitle">Enter Product details</h5>
<form>

  <el-upload
  class="upload-demo"
  drag
  action="https://jsonplaceholder.typicode.com/posts/"
  :on-preview="handlePreview"
  :on-remove="handleRemove"
  :file-list="fileList"
  multiple
>
  <i class="el-icon-upload"></i>
  <div class="el-upload__text">Upload product image <em>click to upload</em></div>
  <template #tip>
    <div class="el-upload__tip">
      jpg/png files with a size less than 200kb
    </div>
  </template>
</el-upload>
<br>

<div class="row">
  <div class="col">
    <label for="inputState">Enter Product name</label>
      <input type="text" class="form-control" placeholder="Product name">
  </div>
  <div class="col">
    <label for="inputState">Enter product description</label>
    <input type="text" class="form-control" placeholder="Product description">
  </div>
</div>


  <el-divider></el-divider>
<h4> Product pricing</h4>
<div class="row">
  <div class="col">
    <label for="inputState">Number of units</label>
      <input type="number" class="form-control" placeholder="Units">
  </div>
  <div class="col">
    <label for="inputState">Price per unit</label>
    <input type="number" class="form-control" placeholder="Unit price">
  </div>
</div>

<div class="row">
  <div class="col">
    <label for="inputState">Mark up per unit</label>
      <input type="number" class="form-control" placeholder="Units">
  </div>
  <div class="col">
    <br>
    <label>Source for this product</label>
    <div class="switch m-r-10">
      <input type="checkbox" id="switch-1" checked="">
      <label for="switch-1"></label>
  </div>
  
  </div>
</div>
</form>
<template #footer>
  <span class="dialog-footer">
    <el-button @click="	addProductDialog = false">Cancel</el-button>
    <el-button type="primary" @click="setprice(0)">Add Product</el-button>
  </span>
</template>
</el-dialog>


<el-dialog
  title=""
  v-model="dialogVisible"
  width="30%"
  >
  <span>Are you sure you want to delete this</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click.prevent="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="confirmDeleteTask()">Confirm</el-button>
    </span>
  </template>
</el-dialog>

<el-dialog
  title=""
  v-model="phaseDialogVisible"
  width="30%"
  >
  <span>Are you sure you want to delete this</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click.prevent="phaseDialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="confirmDeletePhase">Confirm</el-button>
    </span>
  </template>
</el-dialog>


      <div class="page-header">
        <div class="card">
          <div class="card-body">
              <el-steps :active="1" process-status="wait" finish-status="success" simple > 
                <el-step title="Create" icon="el-icon-edit"></el-step>
                <el-step title="Project info" icon="el-icon-s-claim"></el-step>
                <el-step title="Schedule" icon="el-icon-date"></el-step>
                <el-step title="Quotation" icon="el-icon-tickets"></el-step>
                </el-steps>
  
  </div>
    </div>

        <!-- ending -->
      </div>
      <div class="card">

        
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col">
                <h4>ProJect Details</h4>
                <p>Please configure the details of your products</p>
              </div>
              <div class="col text-right">
               <el-button @click.prevent="addPhase" type="primary" icon="el-icon-plus"> New phase</el-button>
              </div>
            </div>
            
          
          <el-collapse  v-model="activeName" accordion>
            <el-collapse-item title="phase.title" :name="phase.id" v-for="(phase, index) in filteredPhases" :key="phase.id" >
              <template #title>
                <div class="row" style="width:100%;" >
                  <div class="col-lg-6 text-left" style="padding-top:10px">
                    {{index + 1 }}. {{phase.title}}
                  </div>
                  <div class="col-lg-6 text-right">

                    <div class="btn-group">
                    
                      <el-button @click.prevent="deletePhase(index)" type="text" size="small" style="margin-right: 15px; z-index: 30;">Delete phase</el-button>

                      <button  class="btn btn-default">
                        
                        
                        <span v-if="phase.hasPrice">Cost: {{ $filters.currencyUSD(phase.price) }}</span>
                        <span v-else>Complimentary</span>

                          
                      </button>
                      <button class="btn btn-icon btn-primary btn-rounded" @click="openprice(index)">
                        <i class="anticon anticon-edit"></i>
                    </button>
                     
                  </div>
                  </div>
                </div>
              </template>
              <el-table v-if="!phase.hasproduct"
                  :data="phase.tasks"
                  :show-header="false"
                  style="width: 100%">
                  <el-table-column
        
                    label="Date"
                    width="50">
                    <template #default="scope">
                      <h5  >{{index + 1 }}.{{scope.$index}}</h5>
                    </template>
                  </el-table-column>

                 
                  <el-table-column
                    prop="title"
                    label="Name"
                    >
                  </el-table-column>
                
                  <el-table-column
                    fixed="right"
                    label="Operations"
                    width="120">
                    <template #default="scope">
                      <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
                    </template>
                  </el-table-column>
                </el-table>
               
                <el-table v-else
                :data="phase.products"
                style="width: 100%">
                <el-table-column
      
                  label=""
                  width="50">
                  <template #default="scope">
                    <h5  >{{index + 1 }}.{{scope.$index}}</h5>
                  </template>
                </el-table-column>

                <el-table-column
                label="Image"
                width="150">

                <template #default="scope">

                 
                  <div class="demo-image__placeholder">
                  <div class="block"> 
                    <el-image :src="scope.row.productimage"></el-image>
                  </div>
                  
                </div>
                </template>
              </el-table-column>
                <el-table-column
                  prop="productname"
                  label="Product Name"
                  >
                </el-table-column>

                <el-table-column
                prop="desc"
                label="Product Desc"
                >
              </el-table-column>
              <el-table-column
              prop="sku"
              label="SKU"
              >
            </el-table-column>
            <el-table-column
            prop="quantity"
            label="Quantity"
            >
          </el-table-column>

          <el-table-column
          prop="unitprice"
          label="Unit Price"
          >
        </el-table-column>

        <el-table-column
        prop="markup"
        label="Mark per unit cost"
        >
      </el-table-column>

      <el-table-column
      prop="total"
      label="Total"
      >
    </el-table-column>
              
                <el-table-column
                  fixed="right"
                  label="Operations"
                  width="120">
                  <template #default="scope">
                    <el-button @click.prevent="deleteTask(index,scope.$index)" type="text" size="small">Delete</el-button>
                  </template>
                </el-table-column>
              </el-table>

                <div class="row" style="width:100%; margin-top:15px" >
                  <div class="col-lg-6 text-left">
                  </div>
                  <div class="col-lg-6 text-right">

                    

                    <el-button  v-if="!phase.hasproduct" @click.prevent="addTask(index)" type="primary" icon="el-icon-plus">Add task</el-button>
                    <el-button  v-else @click.prevent="addProduct(index)" type="primary" icon="el-icon-plus">Add Product</el-button>
                  </div>
                </div>
            </el-collapse-item>
          
          </el-collapse>

       
          <hr>


          <div class="row">
            <div class="col">
                <h5>Enter deposit percentage</h5>
                    <div class="custom-file">
                      <input v-model.number="percentage" maxLength="3" type="text" class="form-control" placeholder="Deposit percentage">
                      
                      
                    </div>
            </div>
            <div class="col text-right">
             <br>
                <button @click="showloading()"  class="btn btn-primary">
                  <span>Generate Quotation</span>
                </button>
              </div>
            </div>
        
          
          </div>
        </div>
      </div>
      <!-- Content Wrapper END -->
    </div>
  </div>