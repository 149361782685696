<!-- Page Container START -->
<div class="page-container">
                
  <!-- Content Wrapper START -->
  <div class="main-content">
      <div class="page-header no-gutters">
          <div class="row align-items-md-center">
              <div class="col-md-12" style="display: flex;">
                  <!-- <div class="row">
                      <div class="col-md-12"> -->
                          <div class="input-affix m-v-10">
                              <i class="prefix-icon anticon anticon-search opacity-04"></i>
                              <input type="text" class="form-control" placeholder="Search Project">
                          </div>
                      <!-- </div>
                  </div> -->
                  <div class="text-md-right m-v-10 m-l-2 m-r-0" style="max-width: 150px; width: 100%;">
                    <router-link class="btn btn-primary" to="/new-project">
                        <i class="anticon anticon-plus"></i>
                        <span class="m-l-5">New Project</span>
                    </router-link>
                </div>
                <div class="text-md-right m-v-10 m-l-5" style="max-width: 170px; width: 100%;">
                    <router-link class="btn btn-primary" to="/create-receipt">
                        <!-- <i class="anticon anticon-plus"></i> -->
                        <span class="m-l-5">Generate Receipt</span>
                    </router-link>
                </div>
              </div>
              <!-- <div class="col-md-2"> -->
                  
              <!-- </div> -->
          </div>
      </div>
      <div class="container-fluid">
          <div id="card-view">
              <div class="row">
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                              
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Mind Cog App</h5>
                                          <span class="text-muted font-size-13">31 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">European minnow priapumfish mosshead warbonnet shrimpfish.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">100%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-cyan">Ready</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Pamela Wanda">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-7.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Darryl Day">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-2.jpg" alt="">
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                   
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Mill Real Estate</h5>
                                          <span class="text-muted font-size-13">56 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Efficiently unleash cross-media information without cross-media value.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">100%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar bg-success" role="progressbar" style="width: 100%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-cyan">Ready</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Virgil Gonzales">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-4.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Erin Gonzales">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="2 More">
                                              <div class="avatar avatar-text avatar-sm">
                                                  <span class="text-dark">+2</span>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                     
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Eastern Sack</h5>
                                          <span class="text-muted font-size-13">21 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Jelly-o sesame snaps halvah croissant oat cake cookie.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">87%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar" role="progressbar" style="width: 87%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-blue">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Lilian Stone">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-10.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Victor Terry">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-11.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="3 More">
                                              <div class="avatar avatar-text avatar-sm">
                                                  <span class="text-dark">+3</span>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                    
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Good Beat</h5>
                                          <span class="text-muted font-size-13">38 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Irish skinny, grinder affogato, dark, sweet carajillo flavour seasonal.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">73%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar" role="progressbar" style="width: 73%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-blue">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Marshall Nichols">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-3.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Pamela Wanda">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-7.jpg" alt="">
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                     
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Fortier Studio</h5>
                                          <span class="text-muted font-size-13">68 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Let us wax poetic about the beauty of the cheeseburger.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">73%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar" role="progressbar" style="width: 73%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-blue">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Erin Gonzales">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-1.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Lilian Stone">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-10.jpg" alt="">
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                    
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Indi Wheel Web</h5>
                                          <span class="text-muted font-size-13">68 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Here's the story of a man named Brady who was busy with three boys</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">62%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar" role="progressbar" style="width: 62%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-blue">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Virgil Gonzales">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-4.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Darryl Day">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-2.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="2 More">
                                              <div class="avatar avatar-text avatar-sm">
                                                  <span class="text-dark">+2</span>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()">
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                   
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Austin Lab</h5>
                                          <span class="text-muted font-size-13">90 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Caerphilly swiss fromage frais. Brie cheese and wine fromage.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Progress</span>
                                      <span class="font-weight-semibold">62%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar" role="progressbar" style="width: 62%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-blue">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Lilian Stone">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-8.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Victor Terry">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-9.jpg" alt="">
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="card"  @click="submit()" >
                          <div class="card-body">
                              <div class="d-flex justify-content-between">
                                  <div class="media">
                                
                                      <div class="m-l-10">
                                          <h5 class="m-b-0">Moody Agency</h5>
                                          <span class="text-muted font-size-13">165 Tasks</span>
                                      </div>
                                  </div>
                                  <div class="dropdown dropdown-animated scale-left">
                                      <a class="text-gray font-size-18" href="javascript:void(0);" data-toggle="dropdown">
                                          <i class="anticon anticon-ellipsis"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-eye"></i>
                                              <span class="m-l-10">View</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-edit"></i>
                                              <span class="m-l-10">Edit</span>
                                          </button>
                                          <button class="dropdown-item" type="button">
                                              <i class="anticon anticon-delete"></i>
                                              <span class="m-l-10">Delete</span>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <p class="m-t-25">Do you see any Teletubbies in here? The path of the righteous.</p>
                              <div class="m-t-30">
                                  <div class="d-flex justify-content-between">
                                      <span class="font-weight-semibold">Behind</span>
                                      <span class="font-weight-semibold">28%</span>
                                  </div>
                                  <div class="progress progress-sm m-t-10">
                                      <div class="progress-bar bg-danger" role="progressbar" style="width: 28%"></div>
                                  </div>
                              </div>
                              <div class="m-t-20">
                                  <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                          <span class="badge badge-pill badge-red">In Progress</span>
                                      </div>
                                      <div>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Lilian Stone">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-8.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="Darryl Day">
                                              <div class="avatar avatar-image avatar-sm">
                                                  <img src="assets/images/avatars/thumb-2.jpg" alt="">
                                              </div>
                                          </a>
                                          <a class="m-r-5" href="javascript:void(0);" data-toggle="tooltip" title="3 More">
                                              <div class="avatar avatar-text avatar-sm">
                                                  <span class="text-dark">+3</span>
                                              </div>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
      </div>
      <div class="modal fade" id="create-new-project">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">Create New Project</h5>
                      <button type="button" class="close" data-dismiss="modal">
                          <i class="anticon anticon-close"></i>
                      </button>
                  </div>
                  <div class="modal-body">
                      <form>
                          <div class="form-group">
                              <label for="new-project-name">Project</label>
                              <input type="text" class="form-control" id="new-project-name" placeholder="Please input project name">
                          </div>
                          <div class="form-group">
                              <label for="new-project-desc">Description</label>
                              <textarea id="new-project-desc" class="form-control" placeholder=""></textarea>
                          </div>
                      </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-primary">Create Project</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- Content Wrapper END -->

  <!-- Footer START -->
  <footer class="footer">
      <div class="footer-content">
          <p class="m-b-0">Copyright © 2019 Theme_Nate. All rights reserved.</p>
          <span>
              <a href="" class="text-gray m-r-15">Term &amp; Conditions</a>
              <a href="" class="text-gray">Privacy &amp; Policy</a>
          </span>
      </div>
  </footer>
  <!-- Footer END -->

</div>
<!-- Page Container END -->