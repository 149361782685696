<template src="./Register.html"></template>

<script>
import { mapActions } from "vuex";
import { ElLoading } from "element-plus";
import apiService from "../../api/apiservice";
import urls from "../../api/apiUrl";
import showError from "../../composables/showError";
export default {
  name: "Home",
  data() {
    return {
      fullName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
    };
  },
  methods: {
    showError,
    ...mapActions(["toggleError", "setErrorMessage"]),

    register: async function () {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });

      try {
        const reqData = {
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          password: this.password,
        };
        const res = await apiService.request(
          false,
          urls.REGISTER,
          reqData,
        );
        const result = await res.json();
        if (result.statuscode !== 200) {
          loadingInstance.close();
          throw new Error(result.message);
        }
        loadingInstance.close();
        this.$router.replace("/");
      } catch (err) {
        loadingInstance.close();
        this.showError(err.message);
        console.log(err);
      }
    },
  },
};
</script>
