<template>
  <transition name="error">
    <div class="error" v-if="isError">{{ errorMessage }}</div>
  </transition>
  <div class="is-success">
    <NavBar v-if="!unauthorisedRoutes.includes($route.name)" />
    <Sidebar v-if="!unauthorisedRoutes.includes($route.name)" />
    <router-view />
    <Bottom />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { mapGetters } from "vuex";
import NavBar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import Bottom from "./components/Bottom.vue";

export default {
  components: {
    NavBar,
    Sidebar,
    Bottom,
  },
  data() {
    const unauthorisedRoutes = ref(["Home", "Register"]);
    return {
      unauthorisedRoutes,
    };
  },

  computed: {
    ...mapGetters({
      isError: "getErrorState",
      errorMessage: "getErrorMessage",
    }),
  },
};
</script>

<style>
html{
  scroll-behavior: smooth;
}
.error {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  padding: 10px 20px;
  background-color: rgba(250, 34, 34, 0.911);
  color: white;
  border-radius: 4px;
  box-shadow: 4px 8px 11px 2px rgb(0 0 0 / 25%);
}

.error-enter-active {
  animation: wobble 0.5s ease forwards;
}

.error-leave-to {
  opacity: 0;
  transform: translate(-50%,-200px);
}

.error-leave-from {
  opacity:1;
  transform: translate(-50%, 5px);
}

.error-leave-active {
  transition: all 1.5s ease-out;
}

@keyframes wobble {
  0% {
    transform: translate(-50%,-100px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%,0px);
    opacity: 1;
  }
  60% {
    transform: translate(calc(-50% + 8px),0px);
    opacity: 1;
  }
  70% {
    transform: translate(calc(-50% - 8px),0px);
    opacity: 1;
  }
  80% {
    transform: translate(calc(-50% + 4px), 0px);
    opacity: 1;
  }
  90% {
    transform: translate(calc(-50% -4px), 0px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0px);
    opacity: 1;
  }
}
</style>
