<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">
      <div class="page-header">
        <ul class="steps">
          <li class="step step-success">
            <div class="step-content">
              <span class="step-circle"
                ><i class="anticon anticon-edit"></i
              ></span>
              <span class="step-text">Create</span>
            </div>
          </li>
          <li class="step step-success">
            <div class="step-content">
              <span class="step-circle"
                ><i class="anticon anticon-calendar"></i
              ></span>
              <span class="step-text">Schedule</span>
            </div>
          </li>
          <li class="step step-success">
            <div class="step-content">
              <span class="step-circle">
                <i class="anticon anticon-folder-open"></i>
              </span>
              <span class="step-text">Documents</span>
            </div>
          </li>
          <li class="step step-success">
            <div class="step-content">
              <span class="step-circle"
                ><i class="anticon anticon-book"></i
              ></span>
              <span class="step-text">Products</span>
            </div>
          </li>
          <li class="step step-active">
            <div class="step-content">
              <span class="step-circle"
                ><i class="anticon anticon-team"></i
              ></span>
              <span class="step-text">Panel</span>
            </div>
          </li>
        </ul>

        <!-- ending -->
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 text-left">
              <router-link to="/new-project-4" class="btn btn-primary">
                <i class="anticon anticon-plus-circle m-r-5"></i>
                <span>Previous</span>
              </router-link>
            </div>
            <div class="col-lg-6 text-right">
              <a href="" class="btn btn-primary">
                <i class="anticon anticon-plus-circle m-r-5"></i>
                <span>+ Add panel</span>
              </a>
            </div>
          </div>
          <br />
          <div class="accordion" id="accordion-default">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">
                  <a data-toggle="collapse" href="#collapseOneDefault">
                    <span>Discovery</span>
                  </a>
                </h5>
              </div>
              <div
                id="collapseOneDefault"
                class="collapse show"
                data-parent="#accordion-default"
              >
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">TasK ID</th>
                          <th scope="col">Title</th>
                          <th scope="col">Assigned Panel</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">TSD734834</th>
                          <td>Table delivery</td>
                          <td>
                            <div class="dropdown">
                              <button
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span>Dropdown button</span>
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#"
                                  >Another action</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                                <a class="dropdown-item" href="#"
                                  >Something else here</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    href="#collapseTwoDefault"
                  >
                    <span>Planning</span>
                  </a>
                </h5>
              </div>
              <div
                id="collapseTwoDefault"
                class="collapse"
                data-parent="#accordion-default"
              >
                <div class="card-body">...</div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    href="#collapseThreeDefault"
                  >
                    <span>Execution</span>
                  </a>
                </h5>
              </div>
              <div
                id="collapseThreeDefault"
                class="collapse"
                data-parent="#accordion-default"
              >
                <div class="card-body">...</div>
              </div>
            </div>
          </div>

          <br />

          <div class="col-lg-12 text-right">
            <router-link to="/project-list" class="btn btn-primary">
              <i class="anticon anticon-plus-circle m-r-5"></i>
              <span>Confirm</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
  <!-- Page Container END -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>