<template src="./receiptlist.html"></template>

 <script>
import { ElLoading } from "element-plus";
import { mapActions } from "vuex";
import showError from "../../composables/showError";
import apiservice from "../../api/apiservice";
import urls from "../../api/apiUrl";
export default {
  methods: {
    ...mapActions(["toggleError", "setErrorMessage"]),
    showError,
    formatDate(value) {
      const dateObj = new Date(value);
      // dateObj = new Date(dateObj.setTime(dateObj.getTime() + (3600000)));
      let hours = dateObj.getHours();
      let minutes = dateObj.getMinutes();
      let month = dateObj.getMonth();

      month = month<10 ? `0${month +1}`: month +1;
      hours %= 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${
        month
      }-${dateObj.getDate()}-${dateObj.getFullYear()} `;
    },
    deleteRow(index, rows) {
      // rows.splice(index, 1);
      console.log(index, rows);
    },
    createReceipt() {
      this.$router.push({ name: "CreateReceipt" });
    },
    async loadReceipts() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      let reqData = { projectID: "60e2f4af9ff93a00128662ee" };
      try {
        let response = await apiservice.request(true, urls.ALLRECEIPT, reqData);

        if (response.status === 200) {
          console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            loadingInstance.close();
            console.log(data);
            this.tableData = data.data.map((item) => ({
              amountPaid: item.amountPaid,
              amountLeft: item.amountLeft,
              paymentMode: item.paymentMode,
              projectID: item.project.projectID,
              name: item.project.projectTitle,
              id: item.receiptID,
              date: this.formatDate(item.createdAt)
            }));
          } else {
            throw new Error(data.message);
          }
        } else {
          throw new Error(response.message);
        }
      } catch (err) {
        loadingInstance.close();
        showError(err.message);
        console.log(err.message);
      }
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.loadReceipts();
  },
};
</script>
 
