<template src="./Profile.html">
            
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>