<template>
  <!-- Page Container START -->
  <div class="page-container">
    <!-- Content Wrapper START -->
    <div class="main-content">
      <div class="page-header">
        <div class="card">
          <div class="card-body">
            <el-steps
              :active="2"
              process-status="wait"
              finish-status="success"
              simple
            >
              <el-step title="Create" icon="el-icon-edit"></el-step>
              <el-step title="Project Details" icon="el-icon-s-claim"></el-step>
              <el-step title="Quotation" icon="el-icon-tickets"></el-step>
            </el-steps>
          </div>
        </div>

        <!-- ending -->
      </div>
      <div class="card">
        <div class="card-body">
          <h4>Customize your Quotation</h4>
          <p>Upload some images to beautify your galley</p>

          <el-upload
            :action="action"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-error="handleError"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :auto-upload="isAutoUpload"
            :on-change="changed"
            :limit="maxFileNumber"
            ref="submit"
            :http-request="Post"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img
              style="object-fit: cover; height: 100%"
              :src="dialogImageUrl"
              alt=""
            />
          </el-dialog>

          <button
            style="margin-top: 20px"
            @click.prevent="submit"
            class="btn btn-primary"
          >
            Upload images
          </button>
          <hr />
          <div class="row">
            <div class="col">
              <h5>Upload 2D Floorplan</h5>
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="customFile" />
                <label class="custom-file-label" for="customFile"
                  >Choose file</label
                >
              </div>
            </div>
            <div class="col">
              <label for="inputState"
                >Input aihouse Panoramic viewer link</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Product description"
              />
            </div>
          </div>

          <br />
          <br />
          <br />
          <button @click.prevent="submit" class="btn btn-primary">
            Preview Quotation
          </button>
          <button @click.prevent="submit" class="btn btn-primary">
            Export Quotation
          </button>
          <button @click.prevent="submit" class="btn btn-primary">
            Generate share link
          </button>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->
  </div>
</template>

<script>
import { ElLoading } from "element-plus";
import { mapActions } from "vuex";
import showError from "../../../composables/showError";
import apiservice from "../../../api/apiservice";
import urls from "../../../api/apiUrl";

export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      imageUrl: "",
      maxFileNumber: 6,
      action: "https://www.github.com/",
      isAutoUpload: false,
      fileList: [],
      fileBlob: null,
      actualFile: null,
      currentFileIndex: 0,
      urls: [],
    };
  },
  computed: {
    // this is to get the extension from each file passed in
    finalArray() {
      return this.fileList.map((el) =>
        el.name.slice(el.name.lastIndexOf(".") + 1)
      );
    },
  },
  methods: {
    ...mapActions(["toggleError", "setErrorMessage"]),
    showError,
    handleRemove(file, fileList) {
      console.log(file, fileList);
      // remove the file
      console.log(this.fileList);
      this.fileList.splice(this.fileList.indexOf(file.name), 1);
    },
    handleError(err, file, fileList) {
      console.log(err);
      this.showError(
        "Something went wrong uploading the image, Please try again"
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },

    Post: async function() {
      let loadingInstance = ElLoading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
      });
      // we are going to be updating our file list here
      // we get the name of the file and take the extension by splittinng on the last index of . till the end of the file
      // let fileName = file;
      // update our array for the api call with the extension
      // this.fileList.push(fileName);
      // console.log(this.fileList);
      let reqData = { fileExts: this.finalArray };
      try {
        let response = await apiservice.request(
          true,
          urls.GETSIGNEDURL,
          reqData
        );
        if (response.status === 200) {
          // loadingInstance.close();
          // console.log(response);
          let data = await response.json();
          if (data.statuscode === 200) {
            
            // log
            // console.log("======== THESE ARE THE URLS");
            let urls = data.data.map((data) => data.uploadURL);
            // console.log(data.data);
            // this.urls
            this.action = data.data[0].uploadURL;
            for (let i = 0; i < urls.length; i++) {
              const url = urls[i];
              try {
                let response = await fetch(url, {
                  method: "PUT",
                  body: this.fileList[i],
                });
                console.log(response);
                let data = await response.json();
                console.log(data);
              } catch (error) {
                console.log(error);
              }
              // const file = this.fileList[i];
              // this.apiCall(url, file);
            }
            loadingInstance.close();
            // console.log(this.action);
          } else {
            loadingInstance.close();
            throw new Error(data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async apiCall(url, file) {},
    async beforeAvatarUpload(file) {},

    changed(file, fileList) {
      if (!this.fileList.includes(file)) {
        this.fileList.push(file);
      }
      // console.log(this.fileList.find);
      // console.log(file);
      // console.log("========= our file list =========");
    },
    submit: function () {
      this.$refs.submit.submit();
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>

<style>
</style>